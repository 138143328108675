import React, { PureComponent } from 'react';
import { Col, Row, Button, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import ScrollBar from 'react-smooth-scrollbar';
import { Paginator } from 'primereact/paginator';

// Icons fontawesome and mdi-react
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShareIcon from 'mdi-react/ShareVariantIcon';
import CalenderQuetionIcon from 'mdi-react/CalendarQuestionIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import ClipboardEditIcon from 'mdi-react/FileDocumentEditOutlineIcon';
import AspectRatio from 'mdi-react/AspectRatioIcon';
import FavIcon from 'mdi-react/HeartOutlineIcon';
import FavIconTrue from 'mdi-react/HeartIcon';
import UserIcon from 'mdi-react/UserCircleIcon';
import CallIcon from 'mdi-react/CallIcon';

import Loader from '../../App/Loader';

// Modals
import ViewPhotosModal from '../../Tables/CommonDataTable/ViewPhotosModal';
import ClientSelectModel from '../components/ClientSelectModal';
import ShareLimitModal from './ShareLimitModal';
import SharePropsModal from './SharePropsModal';
import ConfirmActionModal from '../../Tables/CommonDataTable/ConfirmActionModal';
import ContactSellerModal from './ContactSellerModal';
import LoginFormModal from '../../LogIn/components/LoginFormModal';

// Config
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import fetchMethodRequest from '../../../config/service';
import dateFormats from '../../UI/FormatDate/formatDate';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// Redux Form
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

// Reducers
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { getLoginData } from '../../../redux/actions/loginAction';

// Validate
import validate from '../../Validations/validate';
import asyncValidate from '../../Validations/asyncValidate';

// Input Components
import DefaultInput from '../../../shared/components/form/DefaultInput';
import RadioButton from '../../../shared/components/form/RadioButton';
import Select from '../../../shared/components/form/Select';
import CheckBox from '../../../shared/components/form/CheckBox';

import SessionExpireModal from '../../SessionExpire/sessionExpireModal';
import socket from '../../../config/Socket';


//Connected React component will be able to send messages to the store based on actions
function mapDispatchToProps(dispatch) {
    return {
        changeLoginData: loginUser => {
            dispatch(getLoginData(loginUser))
        }
    };
}

class CommonPropertiesScreen extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            myProperties: [],
            filterCriteria: {
                limit: 12,
                page: 1,
                criteria: [],
                sortfield: 'created',
                direction: 'desc',
            },
            rows: 12,
            first: 1,
            pagination: {},
            isViewPhotosModalOpen: false,
            propertyPhotos: null,
            clientCriteria: null,
            tl: {},
            isOpenSharePropsModal: false,
            isFilterShown: false,
            isGlobalChecked: false,
            cityOptions: [],
            localityOptions: [],
        };
    }

    // This Variable is used for the hiding the elements which is not used rightnow.

     hidElement = false


    // Get Available Cities List
    getCityOptions = async () => {
        fetchMethodRequest('GET', 'locations').then(async resp => {
            if (resp && resp.respCode === 200) {
                let states = resp.states;
                let cities = [];
                const uniqueCities = [...new Set(states.map(item => item.city))];
                uniqueCities.map(item => {
                    cities.push(
                        { label: item, value: item }
                    )
                    return cities;
                })
                await this.setState({
                    cityOptions: cities,
                    city: cities && cities.length > 0 ? cities[0].value : '',
                })
            }
        })
    }

    // Get Available Localities of selected City
    getLocationOptions = async (val) => {
        let url = 'locations';
        let apiUrl = '';
        let city = val ? val : this.state.city ? this.state.city : null;
        let filterCriteria = {};
        filterCriteria['criteria'] = [];
        if (city) {
            filterCriteria.criteria.push(
                {
                    key: 'city',
                    value: city,
                    type: 'eq'
                }
            )
        }
        let filterString = JSON.stringify(filterCriteria);
        apiUrl = `${url}?filter=${filterString}`;
        fetchMethodRequest('GET', apiUrl).then(async resp => {
            if (resp && resp.respCode === 200) {
                let states = resp.states;
                let localities = [];
                const uniqueLocalities = [...new Set(states.map(item => item.locality))];
                uniqueLocalities.map(item => {
                    localities.push(
                        { label: item, value: item }
                    )
                    return localities;
                })
                await this.setState({
                    localityOptions: localities,
                })
            }
        })
    }

    componentDidMount = async () => {
        // await this.getCityOptions();
        let user = localStorage.getItem('loginCredentials') ? JSON.parse(localStorage.getItem('loginCredentials')) : null;
        let tl = {};
        let loc = this.props.location;
        // Clear un-wanted localStorage based on screen and get SelectAll state
        if (window.location.pathname === '/my_properties') {
            localStorage.removeItem('searchPropsSelected')
            localStorage.removeItem('matchedPropsSelected')
            localStorage.removeItem('favPropsSelected')

            localStorage.removeItem('searchIsGlobalChecked')
            localStorage.removeItem('matchedIsGlobalChecked')
            localStorage.removeItem('favIsGlobalChecked')

            localStorage.removeItem('searchPropsDeselected');
            localStorage.removeItem('matchedPropsDeselected');
            localStorage.removeItem('favPropsDeselected');

            if (localStorage.getItem('myIsGlobalChecked')) {
                let isGlobalChecked = (localStorage.getItem('myIsGlobalChecked') &&
                    localStorage.getItem('myIsGlobalChecked') !== 'undefined') ?
                    JSON.parse(localStorage.getItem('myIsGlobalChecked')) : false;
                await this.setState({
                    isGlobalChecked: isGlobalChecked,
                })
            }
        } else if (window.location.pathname === '/search_properties') {
            localStorage.removeItem('myPropsSelected')
            localStorage.removeItem('matchedPropsSelected')
            localStorage.removeItem('favPropsSelected')

            localStorage.removeItem('myIsGlobalChecked')
            localStorage.removeItem('matchedIsGlobalChecked')
            localStorage.removeItem('favIsGlobalChecked')

            localStorage.removeItem('myPropsDeselected');
            localStorage.removeItem('matchedPropsDeselected');
            localStorage.removeItem('favPropsDeselected');

            if (localStorage.getItem('searchIsGlobalChecked')) {
                let isGlobalChecked = (localStorage.getItem('searchIsGlobalChecked') && localStorage.getItem('searchIsGlobalChecked') !== 'undefined') ? JSON.parse(localStorage.getItem('searchIsGlobalChecked')) : false;
                await this.setState({
                    isGlobalChecked: isGlobalChecked,
                })
            }
        } else if (window.location.pathname === '/matched_properties') {
            localStorage.removeItem('myPropsSelected')
            localStorage.removeItem('searchPropsSelected')
            localStorage.removeItem('favPropsSelected')

            localStorage.removeItem('myIsGlobalChecked')
            localStorage.removeItem('searchIsGlobalChecked')
            localStorage.removeItem('favIsGlobalChecked')

            localStorage.removeItem('myPropsDeselected');
            localStorage.removeItem('searchPropsDeselected');
            localStorage.removeItem('favPropsDeselected');

            if (localStorage.getItem('matchedIsGlobalChecked')) {
                let isGlobalChecked = (localStorage.getItem('matchedIsGlobalChecked') && localStorage.getItem('matchedIsGlobalChecked') !== 'undefined') ? JSON.parse(localStorage.getItem('matchedIsGlobalChecked')) : false;
                await this.setState({
                    isGlobalChecked: isGlobalChecked,
                })
            }
        } else if (window.location.pathname === '/favourite_properties') {
            localStorage.removeItem('myPropsSelected')
            localStorage.removeItem('searchPropsSelected')
            localStorage.removeItem('matchedPropsSelected')

            localStorage.removeItem('myIsGlobalChecked')
            localStorage.removeItem('searchIsGlobalChecked')
            localStorage.removeItem('matchedIsGlobalChecked')

            localStorage.removeItem('myPropsDeselected');
            localStorage.removeItem('searchPropsDeselected');
            localStorage.removeItem('matchedPropsDeselected');

            if (localStorage.getItem('favIsGlobalChecked')) {
                let isGlobalChecked = (localStorage.getItem('favIsGlobalChecked') && localStorage.getItem('favIsGlobalChecked') !== 'undefined') ? JSON.parse(localStorage.getItem('favIsGlobalChecked')) : false;
                await this.setState({
                    isGlobalChecked: isGlobalChecked,
                })
            }
        }

        // Get Language Object
        if (loc && loc.state && loc.state.tl) {
            tl = loc.state.tl
        } else {
            tl = (localStorage.getItem('langJson') && localStorage.getItem('langJson') !== 'undefined') ? JSON.parse(localStorage.getItem('langJson')) : {};
        }
        await this.setState({
            isLoading: true,
            loginUser: user,
            tl: tl
        })

        // Get Previous FilterCriteria and related data based on Screen
        if ((loc && loc.state && (loc.state.state && !loc.state.state.clientId))) {
            await this.setState({
                myProperties: loc.state.prevData ? loc.state.prevData : [],
                clientCriteria: loc.state.state.criteria ? loc.state.state.criteria : {},
                screen: loc.state.prevScreen,
                ClientIdForMatcheProps: loc.state.ClientIdForMatcheProps,
                currentFilterCriteria: loc.state.prevFilterCriteria,
                matchedCriteria: loc.state.state,
            })
            if (window.location.pathname === '/search_properties') {
                await this.getMyProperties('', loc.state.prevFilterCriteria, 'matchChecked');
            }
            if (window.location.pathname === '/my_properties' && loc.state.pageLoad) {
                await this.getMyProperties();
            } else if (window.location.pathname === '/my_properties') {
                await this.getMyProperties('', loc.state.prevFilterCriteria, 'matchChecked');
            }
            if (window.location.pathname === '/matched_properties') {
                await this.getMatchedProperties(
                    loc.state.criteriaForMatchedProps ? loc.state.criteriaForMatchedProps : loc.state.state,
                    loc.state.prevFilterCriteria ? loc.state.prevFilterCriteria : this.state.filterCriteria
                );
            }
            if (this.props.load) {
                this.props.load(loc.state.state.criteria ? loc.state.state.criteria : {});
            }
        } else {
            if (this.props.load) {
                await this.props.load({});
            }
            if (window.location.pathname === '/search_properties') {
                let prevCriteria = (localStorage.getItem('myClientCriteria') && localStorage.getItem('myClientCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('myClientCriteria')) : {}
                let prevFilterCriteria = (localStorage.getItem('myFilterCriteria') && localStorage.getItem('myFilterCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('myFilterCriteria')) : this.state.filterCriteria
                let fromScreen = (localStorage.getItem('fromScreen') && localStorage.getItem('fromScreen') !== 'undefined') ? JSON.parse(localStorage.getItem('fromScreen')) : '/'
                let propertyType = null;

                if (prevCriteria && prevCriteria.propertyType && (prevCriteria.propertyType === '1RK' || prevCriteria.propertyType === 'Commercial')) {
                    propertyType = prevCriteria.propertyType;
                }

                if (window.location.pathname === fromScreen || fromScreen === 'home') {
                    await this.setState({
                        currentFilterCriteria: prevFilterCriteria,
                        clientCriteria: prevCriteria,
                        screen: 'searchProps',
                        isLoading: false,
                        propertyType: propertyType,
                    })
                    if (this.props.load) {
                        await this.props.load(this.state.clientCriteria);
                    }
                    await this.getMyProperties(user, this.state.currentFilterCriteria);
                } else {
                    await this.setState({
                        screen: 'searchProps',
                        isLoading: false,
                    })
                }
            } else if (window.location.pathname === '/matched_properties') {
                let prevCriteria = (localStorage.getItem('myClientCriteria') && localStorage.getItem('myClientCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('myClientCriteria')) : null;
                let prevFilterCriteria = (localStorage.getItem('myFilterCriteria') && localStorage.getItem('myFilterCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('myFilterCriteria')) : null;

                await this.getMatchedProperties(loc.state.state, prevFilterCriteria ? prevFilterCriteria : this.state.filterCriteria);
                await this.setState({
                    screen: window.location.pathname,
                    clientCriteria: prevCriteria ? prevCriteria : loc.state.state.criteria,
                    matchedCriteria: loc.state.state,
                    ClientIdForMatcheProps: loc.state.state.clientId,
                    matchedFromScreen: loc.state.matchedFromScreen,
                })

                if (this.state.clientCriteria && this.state.clientCriteria.propertyType && (this.state.clientCriteria.propertyType === '1RK' || this.state.clientCriteria.propertyType === 'Commercial')) {
                    await this.setState({
                        propertyType: this.state.clientCriteria.propertyType,
                    })
                }

            } else if (window.location.pathname === '/my_properties') {
                let prevCriteria = (localStorage.getItem('myClientCriteria') && localStorage.getItem('myClientCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('myClientCriteria')) : {};
                let prevFilterCriteria = (localStorage.getItem('myFilterCriteria') && localStorage.getItem('myFilterCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('myFilterCriteria')) : this.state.filterCriteria;
                let fromScreen = (localStorage.getItem('fromScreen') && localStorage.getItem('fromScreen') !== 'undefined') ? JSON.parse(localStorage.getItem('fromScreen')) : '/';

                let propertyType = null;

                if (prevCriteria && prevCriteria.propertyType && (prevCriteria.propertyType === '1RK' || prevCriteria.propertyType === 'Commercial')) {
                    propertyType = prevCriteria.propertyType;
                }

                if (window.location.pathname === fromScreen) {
                    await this.setState({
                        currentFilterCriteria: prevFilterCriteria,
                        clientCriteria: prevCriteria,
                        propertyType: propertyType,
                    })
                    if (this.props.load) {
                        await this.props.load(this.state.clientCriteria);
                    }
                    await this.getMyProperties(user, this.state.currentFilterCriteria);
                } else {
                    await this.getMyProperties();
                }
            } else if (window.location.pathname === '/favourite_properties') {
                let prevCriteria = (localStorage.getItem('favClientCriteria') && localStorage.getItem('favClientCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('favClientCriteria')) : {};
                let prevFilterCriteria = (localStorage.getItem('favFilterCriteria') && localStorage.getItem('favFilterCriteria') !== 'undefined') ? JSON.parse(localStorage.getItem('favFilterCriteria')) : this.state.filterCriteria;
                let fromScreen = (localStorage.getItem('fromScreen') && localStorage.getItem('fromScreen') !== 'undefined') ? JSON.parse(localStorage.getItem('fromScreen')) : '/';

                let propertyType = null;

                if (prevCriteria && prevCriteria.propertyType && (prevCriteria.propertyType === '1RK' || prevCriteria.propertyType === 'Commercial')) {
                    propertyType = prevCriteria.propertyType;
                }

                if (window.location.pathname === fromScreen) {
                    await this.setState({
                        currentFilterCriteria: prevFilterCriteria,
                        clientCriteria: prevCriteria,
                        propertyType: propertyType,
                    })
                    if (this.props.load) {
                        await this.props.load(this.state.clientCriteria);
                    }
                    await this.getMyProperties(user, this.state.currentFilterCriteria);
                } else {
                    await this.getMyProperties();
                }
            }
            if (this.props.load) {
                if (loc.pathname === '/matched_properties') {
                    this.props.load(this.state.clientCriteria);
                }
            }
        }
        await this.handleLocChange('city', this.state.clientCriteria && this.state.clientCriteria.city ? this.state.clientCriteria.city : null);
        if (this.state.clientCriteria && this.state.clientCriteria.location) {
            let loc = { label: this.state.clientCriteria.location, value: this.state.clientCriteria.location }
            if (this.localitySelectionRef) {
                await this.localitySelectionRef.handleChange(loc);
            }
        }
        const basePath = this.props.match.path;
        if(basePath === '/search_properties/:sharedCriteriaId'){
            const { sharedCriteriaId } = this.props.match.params;
            this.getMyProperties('','','',{sharedCriteriaId});
        }
        localStorage.removeItem('myFilterCriteria');
        localStorage.removeItem('myClientCriteria');
        localStorage.removeItem('fromScreen');
    }

    // Function to get data for Matched Properties Screen
    getMatchedProperties = async (savedCriteria, filter, type) => {
        let id = savedCriteria._id;
        if (!id) {
            id = this.state.matchedCriteria._id
        }
        let filterCriteria = filter ? filter : this.props.location.state.prevFilterCriteria;
        let filterString = JSON.stringify(filterCriteria);
        let url = `criteria/getProperties?filter=${filterString}&id=${id}`;

        if (filterCriteria['page'] <= 1) {
            await this.setState({
                first: 0
            })
        } else {
            await this.setState({
                first: (filterCriteria['page'] - 1) * this.state.rows
            })
        }

        fetchMethodRequest('GET', url).then(async resp => {
            if (resp && resp.respCode) {
                let tempProperties = [];
                if (resp && resp.properties && resp.properties.length > 0) {
                    resp.properties.map(item => {
                        item['isChecked'] = false;
                        return tempProperties.push(item);
                    })
                    if (type && type === 'pagechange' && this.state.isGlobalChecked) {
                        await this.setState({
                            isGlobalChecked: !this.state.isGlobalChecked,
                        })
                        await this.handleDataOnGlobalCheckClicked(tempProperties).then(async resp => {
                            tempProperties = resp;
                        })
                        await this.setState({
                            isGlobalChecked: !this.state.isGlobalChecked,
                        })
                    } else if (window.location.pathname === '/matched_properties') {
                        let properties = [];
                        if (localStorage.getItem('matchedPropsSelected') && localStorage.getItem('matchedPropsSelected') !== 'undefined') {
                            properties = JSON.parse(localStorage.getItem('matchedPropsSelected'))
                        }
                        if (properties && properties.length > 0) {
                            for (let i = 0; i < tempProperties.length; i++) {
                                for (let k = 0; k < properties.length; k++) {
                                    if (tempProperties[i]._id === properties[k]._id) {
                                        tempProperties[i].isChecked = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    tempProperties = [...tempProperties];
                }

                await this.setState({
                    myProperties: tempProperties,
                    pagination: resp.pagination,
                    isLoading: false,
                    savedCriteriaId: id,
                    currentFilterCriteria: filterCriteria,
                })
            } else {
                if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        })
    }


    // Function to get data for My Properties & Search Properties Screen
    getMyProperties = async (user, filterObject, type, additionAalData) => {
        let t = await JSON.parse(localStorage.getItem('langJson'));
        let loginUser;
        if (!user || !user.email) {
            loginUser = JSON.parse(localStorage.getItem('loginCredentials'));
        } else {
            loginUser = user
        }
        let filterCriteria = {};
        if (type && type === 'pagechange') {
            filterCriteria = filterObject;
        } else {
            filterCriteria = filterObject;
            if (!filterCriteria) {
                filterCriteria = this.state.filterCriteria;
            }
            if (filterCriteria['page'] <= 1) {
                await this.setState({
                    first: 0
                })
            } else {
                await this.setState({
                    first: (filterCriteria['page'] - 1) * this.state.rows
                })
            }
        }

        // Pushing email in FilterCriteria to get User properties in My Properties Screen
        if (window.location.pathname === '/my_properties') {
            filterCriteria['criteria'].push({ key: 'email', value: loginUser.email, type: 'eq' });
            if (filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
                let uniq = {}
                filterCriteria['criteria'] = filterCriteria['criteria'].filter(obj => !uniq[obj.key] && (uniq[obj.key] = true));
            } else {
                filterCriteria['criteria'] = [{ key: 'email', value: loginUser.email, type: 'eq' }]
            }
        }

        let filterString = JSON.stringify(filterCriteria);
        let url = `properties?filter=${filterString}`;

        if (window.location.pathname === '/favourite_properties') {
            url = `customers/getFavProperties?email=${loginUser.email}&filter=${filterString}`
        }


        if(additionAalData && additionAalData.sharedCriteriaId){
            url = `sharedCriteria/getSharedProperties/${additionAalData.sharedCriteriaId}`; 
        }

        fetchMethodRequest('GET', url).then(async resp => {
            if (resp && resp.respCode) {
                let tempProperties = [];
                if (resp.properties && resp.properties.length > 0) {
                    resp.properties.map(item => {
                        item['isChecked'] = false;
                        return tempProperties.push(item);
                    })

                    if (type && type === 'pagechange' && this.state.isGlobalChecked) {
                        await this.setState({
                            isGlobalChecked: !this.state.isGlobalChecked,
                        })
                        await this.handleDataOnGlobalCheckClicked(tempProperties).then(async resp => {
                            tempProperties = resp;
                        })
                        await this.setState({
                            isGlobalChecked: !this.state.isGlobalChecked,
                        })
                    } else {
                        if (window.location.pathname === '/my_properties') {
                            let properties = [];
                            if (localStorage.getItem('myPropsSelected') && localStorage.getItem('myPropsSelected') !== 'undefined') {
                                properties = JSON.parse(localStorage.getItem('myPropsSelected'))
                            }
                            if (properties && properties.length > 0) {
                                for (let i = 0; i < tempProperties.length; i++) {
                                    for (let k = 0; k < properties.length; k++) {
                                        if (tempProperties[i]._id === properties[k]._id) {
                                            tempProperties[i].isChecked = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        } else if (window.location.pathname === '/search_properties') {
                            let properties = [];
                            if (localStorage.getItem('searchPropsSelected') && localStorage.getItem('searchPropsSelected') !== 'undefined') {
                                properties = JSON.parse(localStorage.getItem('searchPropsSelected'))
                            }
                            if (properties && properties.length > 0) {
                                for (let i = 0; i < tempProperties.length; i++) {
                                    for (let k = 0; k < properties.length; k++) {
                                        if (tempProperties[i]._id === properties[k]._id) {
                                            tempProperties[i].isChecked = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        } else if (window.location.pathname === '/favourite_properties') {
                            let properties = [];
                            if (localStorage.getItem('favPropsSelected') && localStorage.getItem('favPropsSelected') !== 'undefined') {
                                properties = JSON.parse(localStorage.getItem('favPropsSelected'))
                            }
                            if (properties && properties.length > 0) {
                                for (let i = 0; i < tempProperties.length; i++) {
                                    for (let k = 0; k < properties.length; k++) {
                                        if (tempProperties[i]._id === properties[k]._id) {
                                            tempProperties[i].isChecked = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    tempProperties = [...tempProperties];
                }

                await this.setState({
                    isLoading: false,
                    currentFilterCriteria: filterCriteria,
                    myProperties: tempProperties,
                    pagination: resp.pagination,
                    t: t,
                    clientCriteria: this.state.clientCriteria,
                })
            } else {
                if (resp && resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                        isLoading: false,
                    })
                } else if(resp) {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        })
    }

    //on changing pagination
    onPageChange = async (event, filterObject) => {
        if (this.state.savedCriteriaId) {
            await this.setState({
                isLoading: true,
            })
            let filterCriteria = this.state.filterCriteria;
            let currentPage = event.page + 1;
            filterCriteria['limit'] = event.rows;
            filterCriteria['page'] = currentPage;
            await this.setState({
                rows: event.rows,
                page: event.page,
                first: event.first,
                pageCount: event.pageCount,
            })
            await this.getMatchedProperties(this.props.location.state.state, filterCriteria, 'pagechange');
        } else {
            await this.setState({
                isLoading: true,
            })
            let user = JSON.parse(localStorage.getItem('loginCredentials'));
            let filterCriteria = filterObject;
            let currentPage = event.page + 1;
            filterCriteria['limit'] = event.rows;
            filterCriteria['page'] = currentPage;
            await this.setState({
                rows: event.rows,
                page: event.page,
                first: event.first,
                pageCount: event.pageCount,
                currentFilterCriteria: filterCriteria,
            })
            await this.getMyProperties(user, filterCriteria, 'pagechange');
        }
    }

    // Handle input change
    handleChange = async (name, val) => {
        if (name === 'totalPriceMin' && this.state.priceMinError) {
            await this.setState({
                [`${name}`]: val,
                priceMinError: '',
            })
        } else if (name === 'totalPriceMax' && this.state.priceMaxError) {
            await this.setState({
                [`${name}`]: val,
                priceMaxError: '',
            })
        } else if (name === 'builtUpAreaMin' && this.state.areaMinError) {
            await this.setState({
                [`${name}`]: val,
                areaMinError: '',
            })
        } else if (name === 'builtUpAreaMax' && this.state.areaMaxError) {
            await this.setState({
                [`${name}`]: val,
                areaMaxError: '',
            })
        } else {
            await this.setState({
                [`${name}`]: val,
            })
        }
    }

    // Submit Handler on Clicking Apply Filter button
    applyFilter = async (values) => {
        await this.removeLocalOnApplyOrResetFilter();
        await this.setState({
            isLoading: true,
        })
        let criteria = [];
        let clientCriteria = null;
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        let filterCriteria = this.state.currentFilterCriteria ? this.state.currentFilterCriteria : this.state.filterCriteria;
        filterCriteria.criteria = [];

        if (values.propertyFor) {
            criteria.push({ key: 'propertyFor', value: values.propertyFor, type: 'eq' })
        }
        if (values.propertyType) {
            criteria.push({ key: 'propertyType', value: values.propertyType, type: 'eq' })
        }
        if (values.city) {
            criteria.push({ "key": "city", "value": values.city, "type": "eq" })
        }
        if (values.location) {
            criteria.push({ "key": "locality", "value": values.location, "type": "eq" })
        }
        if (values.totalPriceMin || values.totalPriceMax) {
            if (values.totalPriceMin && values.totalPriceMax) {
                criteria.push({ key: 'totalPrice', value: { "$gte": values.totalPriceMin.replace(/,/g, ''), "$lte": values.totalPriceMax.replace(/,/g, '') }, type: 'eq' })
            } else if (values.totalPriceMin) {
                criteria.push({ key: 'totalPrice', value: { "$gte": values.totalPriceMin.replace(/,/g, '') }, type: 'eq' })
            }
            else if (values.totalPriceMax) {
                criteria.push({ key: 'totalPrice', value: { "$lte": values.totalPriceMax.replace(/,/g, '') }, type: 'eq' })
            }
        }
        if (values.builtUpAreaMin || values.builtUpAreaMax) {
            if (values.builtUpAreaMin && values.builtUpAreaMax) {
                criteria.push({ key: 'builtUpArea', value: { "$gte": values.builtUpAreaMin.replace(/,/g, ''), "$lte": values.builtUpAreaMax.replace(/,/g, '') }, type: 'eq' })
            } else if (values.builtUpAreaMin) {
                criteria.push({ key: 'builtUpArea', value: { "$gte": values.builtUpAreaMin.replace(/,/g, ''), "$lte": values.builtUpAreaMax }, type: 'eq' })
            } else if (values.builtUpAreaMax) {
                criteria.push({ key: 'builtUpArea', value: { "$gte": values.builtUpAreaMin, "$lte": values.builtUpAreaMax }, type: 'eq' })
            }
        }
        if (values.facing) {
            criteria.push({ key: 'facing', value: values.facing, type: 'eq' })
        }
        if (values.bedRooms) {
            criteria.push({ key: 'bedRooms', value: values.bedRooms, type: 'eq' })
        }
        if (values.bathRooms) {
            criteria.push({ key: 'bathRooms', value: values.bathRooms, type: 'eq' })
        }
        if (values.availability) {
            criteria.push({ key: 'availability', value: values.availability, type: 'eq' })
        }
        if (values.furnishingType) {
            criteria.push({ key: 'furnishingType', value: values.furnishingType, type: 'eq' })
        }

        if (criteria && criteria.length > 0) {
            filterCriteria.criteria = criteria;
        } else {
            filterCriteria.criteria = [];
        }
        if (values && values.facing === null) {
            delete values['facing'];
        }

        clientCriteria = values;
        let x = Object.keys(values).length;
        if (x && x > 0) {
            let isFilterShown;
            filterCriteria['page'] = 1;
            if (document.body.clientWidth >= 576) {
                isFilterShown = true;
            } else {
                isFilterShown = false;
            }
            await this.getMyProperties(user, filterCriteria)
            await this.setState({
                clientCriteria: clientCriteria,
                currentCriteria: values,
                currentFilterCriteria: filterCriteria,
                savedCriteriaId: null,
                isGlobalChecked: false,
                isFilterShown: isFilterShown,
            });
        } else {
            await this.setState({
                myProperties: [],
                isLoading: false,
                clientCriteria: null,
                currentCriteria: values,
                currentFilterCriteria: filterCriteria,
                isGlobalChecked: false,
            });
        }
    }

    // Handler to Reset Filters in Filter Container
    resetFilters = async (reset) => {
        await this.removeLocalOnApplyOrResetFilter();
        await this.setState({
            isLoading: true,
            priceMaxError: '',
            priceMinError: '',
            areaMaxError: '',
            areaMinError: '',
        });

        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        let filterCriteria = this.state.filterCriteria;
        filterCriteria.criteria = [];

        if (window.location.pathname === '/my_properties' || window.location.pathname === '/favourite_properties') {
            await this.getMyProperties(user, filterCriteria);
            await this.setState({ isLoading: false, clientCriteria: null, applyFilterCicked: false });
        } else {
            await this.setState({
                isLoading: false,
                priceMaxError: '',
                priceMinError: '',
                areaMaxError: '',
                areaMinError: '',
                myProperties: [],
                clientCriteria: null,
            })
        }

        if (reset) {
            reset();
            if (this.searchFacingSelectRef) {
                this.searchFacingSelectRef.handleChange(null);
            }
        }
        if (this.props.load) {
            this.props.load({})
        }
    }

    // Remove local values on apply or reset filters clicked
    removeLocalOnApplyOrResetFilter = async () => {
        localStorage.removeItem('myPropsSelected')
        localStorage.removeItem('searchPropsSelected')
        localStorage.removeItem('matchedPropsSelected')
        localStorage.removeItem('favPropsSelected')

        localStorage.removeItem('myIsGlobalChecked')
        localStorage.removeItem('searchIsGlobalChecked')
        localStorage.removeItem('matchedIsGlobalChecked')
        localStorage.removeItem('favIsGlobalChecked')

        localStorage.removeItem('myPropsDeselected');
        localStorage.removeItem('searchPropsDeselected');
        localStorage.removeItem('matchedPropsDeselected');
        localStorage.removeItem('favPropsDeselected');
    }

    // Handle View Photos Modal
    viewPhotosModal = async (photos) => {
        if (photos && photos === 'close') {
            await this.setState({
                propertyPhotos: null,
                isViewPhotosModalOpen: false
            })
        } else if (photos) {
            await this.setState({
                propertyPhotos: photos,
                isViewPhotosModalOpen: true
            })
        }
    }

    // Close Contact Seller Modal
    closeContactSellerModal = async () => {
        await this.setState({
            isOpenContactSellerModal: false,
        })
    }

    // Close Login Form Modal
    closeLoginFormModal = async (user) => {
        if (user && user.email) {
            await this.setState({
                loginUser: user,
                isOpenLoginFormModal: false,
                isLoading: false,
            })
            await this.props.changeLoginData(user);
        } else {
            await this.setState({
                isOpenLoginFormModal: false,
                isLoading: false,
            })
        }
    }

    // Handler to open contact seller modal
    handleContactSeller = async (item) => {
        await this.setState({
            isOpenContactSellerModal: true,
            contactProperty: item,
        })
    }

    // Handler to open Save Search to any Client Modal
    openClientSelectModel = async (currentFilter) => {
        if (this.state.loginUser && this.state.loginUser.email) {
            let filterCriteria = currentFilter;
            delete filterCriteria['limit'];
            delete filterCriteria['page'];

            let filterString = JSON.stringify(filterCriteria);
            let url = `properties?filter=${filterString}`;

            fetchMethodRequest('GET', url).then(async resp => {
                if (resp && resp.respCode) {
                    await this.setState({
                        saveSearchProperties: resp.properties,
                        isOpenClientSelectModel: true,
                        saveSearchFilterObject: resp.query,
                    })
                } else {
                    if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        this.setState({ isLoading: false });
                        showToasterMessage(resp.errorMessage, 'error');
                    }
                }
            })
        } else {
            this.setState({
                isOpenLoginFormModal: true,
            })
        }
    }

    // Handler to close Save Searches Modal
    closeClientSelectModal = async (type) => {
        if (type && type === 'saved') {
            this.props.reset();
            if (this.props.load) {
                this.props.load({});
            }
            await this.setState({
                isOpenClientSelectModel: false,
                myProperties: [],
                clientCriteria: null
            })
        } else {
            await this.setState({
                isOpenClientSelectModel: false,
            })
        }
    }

    // Handler to update Saved Criteria
    updateClientCriteria = async () => {
        await this.setState({
            isLoading: true,
        })
        let criteriaId = this.state.matchedCriteria._id;
        let url = '';
        let selectedProperties = [];
        let saveSearchFilterObject = {};
        let body = {};
        let selectedIds = [];

        let filterCriteria = this.state.currentFilterCriteria;
        delete filterCriteria['limit'];
        delete filterCriteria['page'];

        let filterString = JSON.stringify(filterCriteria);

        await fetchMethodRequest('GET', `properties?filter=${filterString}`).then(async resp => {
            if (resp && resp.respCode) {
                selectedProperties = resp.properties;
                saveSearchFilterObject = resp.query;
            } else {
                if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        })

        let clientCriteria = this.state.clientCriteria;

        if (clientCriteria.totalPriceMax) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.totalPriceMax;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.totalPriceMax;
            }
            clientCriteria['totalPriceMaxDigits'] = finalTempNumber;
            clientCriteria['totalPriceMax'] = finalTempString;
        } else {
            delete clientCriteria['totalPriceMaxDigits'];
            delete clientCriteria['totalPriceMax'];
        }

        if (clientCriteria.totalPriceMin) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.totalPriceMin;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.totalPriceMin;
            }

            clientCriteria['totalPriceMinDigits'] = finalTempNumber
            clientCriteria['totalPriceMin'] = finalTempString
        } else {
            delete clientCriteria['totalPriceMinDigits']
            delete clientCriteria['totalPriceMin']
        }

        if (clientCriteria.builtUpAreaMax) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.builtUpAreaMax;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.builtUpAreaMax;
            }

            clientCriteria['builtUpAreaMaxDigits'] = finalTempNumber
            clientCriteria['builtUpAreaMax'] = finalTempString
        } else {
            delete clientCriteria['builtUpAreaMaxDigits']
            delete clientCriteria['builtUpAreaMax']
        }

        if (clientCriteria.builtUpAreaMin) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.builtUpAreaMin;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.builtUpAreaMin;
            }

            clientCriteria['builtUpAreaMinDigits'] = finalTempNumber
            clientCriteria['builtUpAreaMin'] = finalTempString
        } else {
            delete clientCriteria['builtUpAreaMinDigits']
            delete clientCriteria['builtUpAreaMin']
        }

        if (selectedProperties && selectedProperties.length > 0) {
            for (let i = 0; i < selectedProperties.length; i++) {
                selectedIds.push(selectedProperties[i]._id);
            }
        } else {
            selectedIds = [];
        }

        if (clientCriteria['facing'] === null) {
            delete clientCriteria['facing'];
        }

        body = {
            criteria: clientCriteria,
            result: selectedIds,
            search: { filter: saveSearchFilterObject }
        }

        url = `criteria/${criteriaId}`;

        await fetchMethodRequest('PUT', url, body).then(async resp => {
            if (resp && resp.respCode) {
                showToasterMessage(resp.respMessage, 'success')
                await this.setState({
                    isLoading: false,
                    redirectToPrevScreenFromMatchedProps: true,
                })
            } else {
                if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        })
    }

    // Handler to redirect to previuos screen from Matched properties
    redirectToPrevScreenFromMatchedProps = () => {
        this.setState({
            redirectToPrevScreenFromMatchedProps: true,
        })
    }

    // Handler triggered when clicked on Select All or Clear All Button
    handleGlobalCheckBox = async (data) => {
        await this.handleDataOnGlobalCheckClicked(data).then(async resp => {
            let isAtleastOneChecked = resp.findIndex((obj => obj.isChecked === true)) + 1;
            await this.setState({
                isGlobalChecked: isAtleastOneChecked >= 1 ? true : false,
                myProperties: resp,
            })
        })
        if (window.location.pathname === '/my_properties') {
            localStorage.setItem('myIsGlobalChecked', JSON.stringify(this.state.isGlobalChecked));
        } else if (window.location.pathname === '/search_properties') {
            localStorage.setItem('searchIsGlobalChecked', JSON.stringify(this.state.isGlobalChecked));
        } else if (window.location.pathname === '/matched_properties') {
            localStorage.setItem('matchedIsGlobalChecked', JSON.stringify(this.state.isGlobalChecked));
        } else if (window.location.pathname === '/favourite_properties') {
            localStorage.setItem('favIsGlobalChecked', JSON.stringify(this.state.isGlobalChecked));
        }
    }

    // Handler to manipulate select all or clear all action
    handleDataOnGlobalCheckClicked = async (data) => {
        let myPropsSelected = [];
        let searchPropsSelected = [];
        let matchedPropsSelected = [];
        let favPropsSelected = [];


        let myPropsDeselected = [];
        let searchPropsDeselected = [];
        let matchedPropsDeselected = [];
        let favPropsDeselected = [];

        let properties = data;
        let concatAndDeDuplicateObjects = (p, ...arrs) => [].concat(...arrs).reduce((a, b) => !a.filter(c => b[p] === c[p]).length ? [...a, b] : a, []);
        if (window.location.pathname === '/my_properties') {
            myPropsSelected = localStorage.getItem('myPropsSelected') ? JSON.parse(localStorage.getItem('myPropsSelected')) : [];
            myPropsDeselected = localStorage.getItem('myPropsDeselected') ? JSON.parse(localStorage.getItem('myPropsDeselected')) : [];
            let isAtleastOneChecked = properties.findIndex((obj => obj.isChecked === true)) + 1;
            if (this.state.isGlobalChecked || isAtleastOneChecked >= 1) {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = isAtleastOneChecked >= 1 ? false : !this.state.isGlobalChecked;
                }
                localStorage.removeItem('myPropsSelected');
                localStorage.removeItem('myPropsDeselected');
            } else {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = !this.state.isGlobalChecked;
                }

                myPropsSelected = concatAndDeDuplicateObjects('_id', myPropsSelected, properties);

                for (let i = 0; i < properties.length; i++) {
                    for (let j = 0; j < myPropsDeselected.length; j++) {
                        if (myPropsDeselected[j]._id === properties[i]._id) {
                            properties[i].isChecked = false;
                        }
                    }
                }
                for (let i = 0; i < myPropsSelected.length; i++) {
                    for (let j = 0; j < myPropsDeselected.length; j++) {
                        if (myPropsDeselected[j]._id === myPropsSelected[i]._id) {
                            myPropsSelected.splice(i, 1);
                            i--;
                        }
                    }
                }
                localStorage.setItem('myPropsSelected', JSON.stringify(myPropsSelected));
            }
        } else if (window.location.pathname === '/search_properties') {
            searchPropsSelected = (localStorage.getItem('searchPropsSelected') && localStorage.getItem('searchPropsSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('searchPropsSelected')) : [];
            searchPropsDeselected = (localStorage.getItem('searchPropsDeselected') && localStorage.getItem('searchPropsDeselected') !== 'undefined') ? JSON.parse(localStorage.getItem('searchPropsDeselected')) : [];
            let isAtleastOneChecked = properties.findIndex((obj => obj.isChecked === true)) + 1;
            if (this.state.isGlobalChecked || isAtleastOneChecked >= 1) {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = isAtleastOneChecked >= 1 ? false : !this.state.isGlobalChecked;
                }
                localStorage.removeItem('searchPropsSelected');
                localStorage.removeItem('searchPropsDeselected');
            } else {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = !this.state.isGlobalChecked;
                }

                searchPropsSelected = concatAndDeDuplicateObjects('_id', searchPropsSelected, properties);

                for (let i = 0; i < properties.length; i++) {
                    for (let j = 0; j < searchPropsDeselected.length; j++) {
                        if (searchPropsDeselected[j]._id === properties[i]._id) {
                            properties[i].isChecked = false;
                        }
                    }
                }
                for (let i = 0; i < searchPropsSelected.length; i++) {
                    for (let j = 0; j < searchPropsDeselected.length; j++) {
                        if (searchPropsDeselected[j]._id === searchPropsSelected[i]._id) {
                            searchPropsSelected.splice(i, 1);
                            i--;
                        }
                    }
                }
                localStorage.setItem('searchPropsSelected', JSON.stringify(searchPropsSelected));
            }
        } else if (window.location.pathname === '/matched_properties') {
            matchedPropsSelected = (localStorage.getItem('matchedPropsSelected') && localStorage.getItem('matchedPropsSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('matchedPropsSelected')) : [];
            matchedPropsDeselected = (localStorage.getItem('matchedPropsDeselected') && localStorage.getItem('matchedPropsDeselected') !== 'undefined') ? JSON.parse(localStorage.getItem('matchedPropsDeselected')) : [];
            let isAtleastOneChecked = properties.findIndex((obj => obj.isChecked === true)) + 1;
            if (this.state.isGlobalChecked || isAtleastOneChecked >= 1) {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = isAtleastOneChecked >= 1 ? false : !this.state.isGlobalChecked;
                }
                localStorage.removeItem('matchedPropsSelected');
                localStorage.removeItem('matchedPropsDeselected');
            } else {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = !this.state.isGlobalChecked;
                }

                matchedPropsSelected = concatAndDeDuplicateObjects('_id', matchedPropsSelected, properties);

                for (let i = 0; i < properties.length; i++) {
                    for (let j = 0; j < matchedPropsDeselected.length; j++) {
                        if (matchedPropsDeselected[j]._id === properties[i]._id) {
                            properties[i].isChecked = false;
                        }
                    }
                }
                for (let i = 0; i < matchedPropsSelected.length; i++) {
                    for (let j = 0; j < matchedPropsDeselected.length; j++) {
                        if (matchedPropsDeselected[j]._id === matchedPropsSelected[i]._id) {
                            matchedPropsSelected.splice(i, 1);
                            i--;
                        }
                    }
                }
                localStorage.setItem('matchedPropsSelected', JSON.stringify(matchedPropsSelected));
            }
        } else if (window.location.pathname === '/favourite_properties') {
            favPropsSelected = localStorage.getItem('favPropsSelected') ? JSON.parse(localStorage.getItem('favPropsSelected')) : [];
            favPropsDeselected = localStorage.getItem('favPropsDeselected') ? JSON.parse(localStorage.getItem('favPropsDeselected')) : [];
            let isAtleastOneChecked = properties.findIndex((obj => obj.isChecked === true)) + 1;
            if (this.state.isGlobalChecked || isAtleastOneChecked >= 1) {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = isAtleastOneChecked >= 1 ? false : !this.state.isGlobalChecked;
                }
                localStorage.removeItem('favPropsSelected');
                localStorage.removeItem('favPropsDeselected');
            } else {
                for (let i = 0; i < properties.length; i++) {
                    properties[i].isChecked = !this.state.isGlobalChecked;
                }

                favPropsSelected = concatAndDeDuplicateObjects('_id', favPropsSelected, properties);

                for (let i = 0; i < properties.length; i++) {
                    for (let j = 0; j < favPropsDeselected.length; j++) {
                        if (favPropsDeselected[j]._id === properties[i]._id) {
                            properties[i].isChecked = false;
                        }
                    }
                }
                for (let i = 0; i < favPropsSelected.length; i++) {
                    for (let j = 0; j < favPropsDeselected.length; j++) {
                        if (favPropsDeselected[j]._id === favPropsSelected[i]._id) {
                            favPropsSelected.splice(i, 1);
                            i--;
                        }
                    }
                }
                localStorage.setItem('favPropsSelected', JSON.stringify(favPropsSelected));
            }
        }

        let newArray = [...properties];

        return newArray;
    }

    // Handler to Check or Un-Check Individual Property
    handleCheckBox = async (item) => {
        let id = item._id;
        let properties = this.state.myProperties;
        let objIndex = properties.findIndex((obj => obj._id === id));

        let myPropsSelected = [];
        let searchPropsSelected = [];
        let matchedPropsSelected = [];
        let favPropsSelected = [];
        let prop = [];

        let myPropsDeselected = [];
        let searchPropsDeselected = [];
        let matchedPropsDeselected = [];
        let favPropsDeselected = [];
        let delProp = [];
        let concatAndDeDuplicateObjects = (p, ...arrs) => [].concat(...arrs).reduce((a, b) => !a.filter(c => b[p] === c[p]).length ? [...a, b] : a, []);

        if (window.location.pathname === '/my_properties') {
            myPropsSelected = (localStorage.getItem('myPropsSelected') && localStorage.getItem('myPropsSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('myPropsSelected')) : []
            myPropsDeselected = (localStorage.getItem('myPropsDeselected') && localStorage.getItem('myPropsDeselected') !== 'undefined') ? JSON.parse(localStorage.getItem('myPropsDeselected')) : []

            if (properties[objIndex].isChecked) {
                delProp.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;
                let propIndex = myPropsSelected.findIndex((obj => obj._id === id));
                myPropsSelected.splice(propIndex, 1);

                myPropsDeselected = concatAndDeDuplicateObjects('_id', myPropsDeselected, delProp);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('myPropsDeselected', JSON.stringify(myPropsDeselected));
                }

                localStorage.setItem('myPropsSelected', JSON.stringify(myPropsSelected));
            } else {
                prop.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;

                let propIndex = myPropsDeselected.findIndex((obj => obj._id === id));
                myPropsDeselected.splice(propIndex, 1);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('myPropsDeselected', JSON.stringify(myPropsDeselected));
                }

                myPropsSelected = concatAndDeDuplicateObjects('_id', myPropsSelected, prop);

                localStorage.setItem('myPropsSelected', JSON.stringify(myPropsSelected));
            }
        } else if (window.location.pathname === '/search_properties') {
            searchPropsSelected = (localStorage.getItem('searchPropsSelected') && localStorage.getItem('searchPropsSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('searchPropsSelected')) : []
            searchPropsDeselected = (localStorage.getItem('searchPropsDeselected') && localStorage.getItem('searchPropsDeselected') !== 'undefined') ? JSON.parse(localStorage.getItem('searchPropsDeselected')) : []

            if (properties[objIndex].isChecked) {
                delProp.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;
                let propIndex = searchPropsSelected.findIndex((obj => obj._id === id));
                searchPropsSelected.splice(propIndex, 1);

                searchPropsDeselected = concatAndDeDuplicateObjects('_id', searchPropsDeselected, delProp);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('searchPropsDeselected', JSON.stringify(searchPropsDeselected));
                }

                localStorage.setItem('searchPropsSelected', JSON.stringify(searchPropsSelected));
            } else {
                prop.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;

                let propIndex = searchPropsDeselected.findIndex((obj => obj._id === id));
                searchPropsDeselected.splice(propIndex, 1);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('searchPropsDeselected', JSON.stringify(searchPropsDeselected));
                }

                searchPropsSelected = concatAndDeDuplicateObjects('_id', searchPropsSelected, prop);

                localStorage.setItem('searchPropsSelected', JSON.stringify(searchPropsSelected));
            }
        } else if (window.location.pathname === '/matched_properties') {
            matchedPropsSelected = (localStorage.getItem('matchedPropsSelected') && localStorage.getItem('matchedPropsSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('matchedPropsSelected')) : []
            matchedPropsDeselected = (localStorage.getItem('matchedPropsDeselected') && localStorage.getItem('matchedPropsDeselected') !== 'undefined') ? JSON.parse(localStorage.getItem('matchedPropsDeselected')) : []

            if (properties[objIndex].isChecked) {
                delProp.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;
                let propIndex = matchedPropsSelected.findIndex((obj => obj._id === id));
                matchedPropsSelected.splice(propIndex, 1);

                matchedPropsDeselected = concatAndDeDuplicateObjects('_id', matchedPropsDeselected, delProp);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('matchedPropsDeselected', JSON.stringify(matchedPropsDeselected));
                }

                localStorage.setItem('matchedPropsSelected', JSON.stringify(matchedPropsSelected));
            } else {
                prop.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;

                let propIndex = matchedPropsDeselected.findIndex((obj => obj._id === id));
                matchedPropsDeselected.splice(propIndex, 1);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('matchedPropsDeselected', JSON.stringify(matchedPropsDeselected));
                }

                matchedPropsSelected = concatAndDeDuplicateObjects('_id', matchedPropsSelected, prop);

                localStorage.setItem('matchedPropsSelected', JSON.stringify(matchedPropsSelected));
            }
        } else if (window.location.pathname === '/favourite_properties') {
            favPropsSelected = (localStorage.getItem('favPropsSelected') && localStorage.getItem('favPropsSelected') !== 'undefined') ? JSON.parse(localStorage.getItem('favPropsSelected')) : []
            favPropsDeselected = (localStorage.getItem('favPropsDeselected') && localStorage.getItem('favPropsDeselected') !== 'undefined') ? JSON.parse(localStorage.getItem('favPropsDeselected')) : []

            if (properties[objIndex].isChecked) {
                delProp.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;
                let propIndex = favPropsSelected.findIndex((obj => obj._id === id));
                favPropsSelected.splice(propIndex, 1);

                favPropsDeselected = concatAndDeDuplicateObjects('_id', favPropsDeselected, delProp);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('favPropsDeselected', JSON.stringify(favPropsDeselected));
                }

                localStorage.setItem('favPropsSelected', JSON.stringify(favPropsSelected));
            } else {
                prop.push(properties[objIndex]);
                properties[objIndex].isChecked = !properties[objIndex].isChecked;

                let propIndex = favPropsDeselected.findIndex((obj => obj._id === id));
                favPropsDeselected.splice(propIndex, 1);

                if (this.state.isGlobalChecked) {
                    localStorage.setItem('favPropsDeselected', JSON.stringify(favPropsDeselected));
                }

                favPropsSelected = concatAndDeDuplicateObjects('_id', favPropsSelected, prop);

                localStorage.setItem('favPropsSelected', JSON.stringify(favPropsSelected));
            }
        }

        let newArray = [...properties];
        await this.setState({
            myProperties: newArray,
        })


    }

    // Handler to share selected properties
    handleGLobalShare = async () => {
        let propsToShare = [];
        if (window.location.pathname === '/my_properties') {
            if (localStorage.getItem('myPropsSelected') && localStorage.getItem('myPropsSelected') !== 'undefined') {
                propsToShare = JSON.parse(localStorage.getItem('myPropsSelected'))
            }
        } else if (window.location.pathname === '/search_properties') {
            if (localStorage.getItem('searchPropsSelected') && localStorage.getItem('searchPropsSelected') !== 'undefined') {
                propsToShare = JSON.parse(localStorage.getItem('searchPropsSelected'))
            }
        } else if (window.location.pathname === '/matched_properties') {
            if (localStorage.getItem('matchedPropsSelected') && localStorage.getItem('matchedPropsSelected') !== 'undefined') {
                propsToShare = JSON.parse(localStorage.getItem('matchedPropsSelected'))
            }
        } else if (window.location.pathname === '/favourite_properties') {
            if (localStorage.getItem('favPropsSelected') && localStorage.getItem('favPropsSelected') !== 'undefined') {
                propsToShare = JSON.parse(localStorage.getItem('favPropsSelected'))
            }
        }
        let selectedProperties = propsToShare;

        if (selectedProperties && selectedProperties.length > config.shareLimit) {
            await this.setState({
                isOpenShareLimitModal: true
            })
        } else {
            await this.setState({
                isOpenSharePropsModal: true,
                selectedProperties: selectedProperties,
            })
        }
    }

    // Handler to share individual property
    handleSingleShare = async (event, prop) => {
        let selectedProperties = [];
        selectedProperties.push(prop);

        await this.setState({
            isOpenSharePropsModal: true,
            selectedProperties: selectedProperties,
        })
    }

    // To close share props modal
    closeSharePropsModal = async () => {
        await this.setState({
            isOpenSharePropsModal: false,
        })
    }

    // To close share limit modal
    closeShareLimitModal = async () => {
        await this.setState({
            isOpenShareLimitModal: false,
        })
    }

    //Open Confirmation Modal For Deleting Property/Properties
    deleteConfirmAction = async (event, propertyId) => {
        if (propertyId) {
            await this.setState({
                deleteRowDataId: propertyId,
                openConfirmActionModal: true,
                actionTitle: 'propertyDeleteConfirmationText',
                actionType: 'deleteProperty'
            });
        } else {
            let propsToShare = [];
            if (localStorage.getItem('myPropsSelected') && localStorage.getItem('myPropsSelected') !== 'undefined') {
                propsToShare = JSON.parse(localStorage.getItem('myPropsSelected'))
            }

            let selectedRowsId = [];
            let selectedRows = propsToShare;

            if (selectedRows && selectedRows.length && selectedRows.length > 0) {
                let actionTitle = '';
                selectedRows.forEach((item, index) => {
                    selectedRowsId.push(item._id)
                })
                if (selectedRows.length === 1) {
                    actionTitle = 'deletePropConfirmationTitle';
                } else {
                    actionTitle = 'deletePropsConfirmationTitle';
                }
                await this.setState({
                    selectedRowsId: selectedRowsId,
                    openConfirmActionModal: true,
                    actionTitle: actionTitle,
                    actionType: 'deleteProperty'
                })
            }
        }
    }

    // Close Confirm Action Modal
    closeConfirmActionModal = async () => {
        await this.setState({
            openConfirmActionModal: false,
            deleteRowDataId: null,
        })
    }

    // Delete selected Property
    deleteSelectedRow = async () => {
        let method, url, body = {};

        url = 'properties/deleteProperties';
        method = 'PUT';

        if (this.state.deleteRowDataId) {
            let ids = []
            ids.push(this.state.deleteRowDataId);
            body['properties'] = [...ids]
        } else {
            body['properties'] = [...this.state.selectedRowsId];
        }

        fetchMethodRequest(method, url, body).then(async (response) => {
            this.setState({
                openConfirmActionModal: false
            });
            if (response && response.respCode) {
                showToasterMessage(response.respMessage, 'success');
                if (this.state.deleteRowDataId) {
                } else {
                    localStorage.removeItem('myPropsSelected')
                }
                await this.getMyProperties();

            } else {
                if (response.errorMessage && response.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(response.errorMessage, 'error');
                }
            }
        }).catch((err) => {
            return err;
        });
    }

    // Handle Property Card onClick
    handlePropCardClick = (e) => {
        if (e._dispatchInstances && e._dispatchInstances.length && e._dispatchInstances.length > 1) {
            e.preventDefault();
        }
    }

    // Handler to show or hide Contact info on Property Card
    handleContactInfo = (item) => {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        let id = item._id;
        let properties = this.state.myProperties;
        let objIndex = properties.findIndex((obj => obj._id === id));
        properties[objIndex].showContactInfo = !properties[objIndex].showContactInfo;
        let newArray = [...properties];

        if (item.showContactInfo) {
            socket.emit('agentViewed', { userId: user._id, email: item.email })
        }
        this.setState({
            myProperties: newArray,
        })
    }

    // Handle Favourite Icon Click
    handlePropFav = async (action, prop) => {
        await this.setState({
            isLoading: true,
        })
        if (this.state.loginUser && this.state.loginUser.email) {
            let email = this.state.loginUser.email;
            let apiUrl = 'customers/favAndUnFav';
            let method = 'PUT';
            let type = action;
            let body = {
                properties: [prop._id]
            }
            let url = `${apiUrl}?email=${email}&type=${type}`
            fetchMethodRequest(method, url, body).then(async res => {
                if (res && res.respCode) {
                    showToasterMessage(res.respMessage, 'success')
                    if (type && type === 'unfav') {
                        if (window.location.pathname === '/favourite_properties') {
                            await this.getMyProperties(this.state.loginUser, this.state.currentFilterCriteria);
                        } else {
                            await this.setState({
                                isLoading: false,
                                [`${prop._id}`]: false,
                            })
                        }
                    } else {
                        await this.setState({
                            isLoading: false,
                            [`${prop._id}`]: true,
                        })
                    }
                    let property = prop;
                    let favProperties = [];
                    let updateFavs = [];
                    if (localStorage.getItem('favProperties') && localStorage.getItem('favProperties') !== 'undefined') {
                        favProperties = JSON.parse(localStorage.getItem('favProperties'));
                    }
                    if (Array.isArray(favProperties)) {
                    } else {
                        favProperties = [];
                    }
                    if (type === 'fav') {
                        property['isFavourite'] = true;
                        favProperties = favProperties.filter(id => {
                            return id !== property._id;
                        });
                        favProperties.push(property._id);
                        updateFavs = [...favProperties];
                    } else {
                        property['isFavourite'] = false;
                        updateFavs = favProperties.filter(id => {
                            return id !== property._id;
                        });
                    }
                    let newFavProps = [...updateFavs];
                    await localStorage.setItem('favProperties', JSON.stringify(newFavProps))
                } else {
                    if (res && res.errorMessage && res.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                            isLoading: false,
                        })
                    } else if (res && res.errorMessage) {
                        showToasterMessage(res.errorMessage, 'error')
                        this.setState({
                            isLoading: false,
                        });
                    } else {
                        showToasterMessage('Network Error', 'error')
                        this.setState({
                            isLoading: false,
                        });
                    }
                }
            }).catch(async err => {
                return err;
            })
        } else {
            this.setState({
                isOpenLoginFormModal: true,
            })
        }
    }

    // Redirect to Home screen handler
    redirectToHome = () => {
        this.setState({
            openConfirmActionModal: false,
            isRedirectToHome: true,
        })
    }

    // Dropdown option selection Handler
    handleLocChange = async (name, val) => {
        await this.setState({
            [`${name}`]: val,
        })
        if (name && name === 'city') {
            // await this.getLocationOptions(val);
            if (this.localitySelectionRef) {
                await this.localitySelectionRef.handleChange(null)
            }
        }
    }

    render() {
        const { tl, myProperties, loginUser, cityOptions, localityOptions } = this.state;
        const { handleSubmit, reset } = this.props;
        const isAtleastOneChecked = myProperties.findIndex((obj => obj.isChecked === true)) + 1;

        window.addEventListener("resize", async () => {
            if (document.body.clientWidth >= 576) {
                await this.setState({ isFilterShown: true })
            }
        })
        return (
            <div className='addPropertyScreen px-0 px-sm-2'>
                <Loader className='propertiesScreenLoader' loader={this.state.isLoading} />
                <Row className='ml-0 mr-2 mx-md-2'>
                    {/* Page Title start here */}
                    <div className='pageHeadingCss listScreenHeaderTitleDivCss d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block'>
                        {window.location.pathname === '/search_properties' ?
                            tl['searchProperties'] ? tl['searchProperties'] : 'Search Properties'
                            : window.location.pathname === '/matched_properties' ?
                                tl['savedSearches'] ? tl['savedSearches'] : 'Saved Searches'
                                : window.location.pathname === '/my_properties' ?
                                    tl['myProperties'] ? tl['myProperties'] : 'My Properties'
                                    : window.location.pathname === '/favourite_properties' ?
                                        tl['favouriteProps'] ? tl['favouriteProps'] : 'Favourite Properties' :
                                        this.props.match.path == '/search_properties/:sharedCriteriaId' ?  'Matched Properties' 
                                        : null 
                        }
                    </div>
                    {/* Page Title end here */}

                    {/* Page header buttons start here */}
                    {this.hidElement && <div style={{ width: '100%' }} className='listScreenHeaderButtonCss'>
                        {window.location.pathname && window.location.pathname === '/search_properties' ?
                            <div className='text-right'>
                                {
                                    this.state.clientCriteria &&
                                        this.state.myProperties && this.state.myProperties.length > 0 ?
                                        <Button
                                            color='primary'
                                            type='button'
                                            disabled={isAtleastOneChecked && isAtleastOneChecked >= 0 ? false : true}
                                            onClick={isAtleastOneChecked && isAtleastOneChecked >= 0 ? this.handleGLobalShare : null}
                                            className='mr-0 ml-2 buttonTextWeight mb-1'
                                        >
                                            {tl['share'] ? tl['share'] : 'Share'}
                                        </Button>
                                        : null
                                }
                                {
                                    this.state.clientCriteria &&
                                        this.state.myProperties && this.state.myProperties.length > 0 ?
                                        <Button
                                            color='primary'
                                            onClick={() => this.handleGlobalCheckBox(this.state.myProperties)}
                                            className='mr-0 ml-2 buttonTextWeight mb-1'
                                        >
                                            {this.state.isGlobalChecked || (isAtleastOneChecked !== 0) ?
                                                tl['clearAll'] ? tl['clearAll'] : 'Clear All'
                                                : tl['selectAll'] ? tl['selectAll'] : 'Select All'
                                            }
                                        </Button>
                                        : null
                                }
                                {
                                    this.state.clientCriteria ?
                                        <Button
                                            color={'primary'}
                                            onClick={
                                                (this.state.clientCriteria || this.state.currentFilterCriteria) ?
                                                    () => this.openClientSelectModel(this.state.currentFilterCriteria)
                                                    : null
                                            }
                                            className='mr-0 ml-2 buttonTextWeight mb-1'
                                        >
                                            {tl['saveSearch'] ? tl['saveSearch'] : 'Save Search'}
                                        </Button>
                                        : null
                                }
                                {/* Filter Container show hide buttons based on screen width start here */}
                                {this.state.isFilterShown ?
                                    <Button
                                        className='showInMobile mr-0 ml-2 buttonTextWeight mb-1'
                                        color='primary'
                                        onClick={() => this.setState({ isFilterShown: false })}
                                    >
                                        {tl['hideFilters'] ? tl['hideFilters'] : 'Hide Filters'}
                                    </Button>

                                    :
                                    <Button
                                        className='showInMobile mr-0 ml-2 buttonTextWeight mb-1'
                                        color='primary'
                                        onClick={() => this.setState({ isFilterShown: true })}
                                    >
                                        {tl['showFilters'] ? tl['showFilters'] : 'Show Filters'}
                                    </Button>
                                }
                                {/* Filter Container show hide buttons based on screen width end here */}
                            </div>
                            : window.location.pathname && window.location.pathname === '/matched_properties' ?
                                <div className='text-right'>
                                    {
                                        this.state.myProperties && this.state.myProperties.length > 0 ?
                                            <Button
                                                className='mr-0 ml-2 buttonTextWeight mb-1'
                                                color='primary'
                                                disabled={isAtleastOneChecked && isAtleastOneChecked >= 0 ? false : true}
                                                onClick={isAtleastOneChecked && isAtleastOneChecked >= 0 ? this.handleGLobalShare : null}
                                            >
                                                {tl['share'] ? tl['share'] : 'Share'}
                                            </Button>
                                            : null
                                    }
                                    {
                                        this.state.myProperties && this.state.myProperties.length > 0 ?

                                            <Button
                                                className='mr-0 ml-2 buttonTextWeight mb-1'
                                                color='primary'
                                                onClick={() => this.handleGlobalCheckBox(this.state.myProperties)}
                                            >
                                                {this.state.isGlobalChecked || (isAtleastOneChecked !== 0) ?
                                                    tl['clearAll'] ? tl['clearAll'] : 'Clear All'
                                                    : tl['selectAll'] ? tl['selectAll'] : 'Select All'
                                                }
                                            </Button>
                                            : null
                                    }
                                    {this.state.clientCriteria ?
                                        <Button color='primary' className='criteriaUpdateCss mr-0 ml-2 buttonTextWeight mb-1'
                                            disabled={this.state.savedCriteriaId ? true : false}
                                            onClick={this.state.clientCriteria ? () => this.updateClientCriteria() : null}
                                        >
                                            <ClipboardEditIcon className='mt-0' />
                                            {tl['update'] ? tl['update'] : 'Update'}
                                        </Button>
                                        : null
                                    }
                                    {/* Filter Container show hide buttons based on screen width start here */}
                                    {this.state.isFilterShown ?
                                        <Button
                                            className='showInMobile mr-0 ml-2 buttonTextWeight mb-1'
                                            color='primary'
                                            onClick={() => this.setState({ isFilterShown: false })}
                                        >
                                            {tl['hideFilters'] ? tl['hideFilters'] : 'Hide Filters'}
                                        </Button>

                                        :
                                        <Button
                                            className='showInMobile mr-0 ml-2 buttonTextWeight mb-1'
                                            color='primary'
                                            onClick={() => this.setState({ isFilterShown: true })}
                                        >
                                            {tl['showFilters'] ? tl['showFilters'] : 'Show Filters'}
                                        </Button>
                                    }
                                    {/* Filter Container show hide buttons based on screen width end here */}
                                </div>
                                : window.location.pathname && window.location.pathname === '/favourite_properties' ?
                                    <div className='text-right'>
                                        {
                                            this.state.myProperties && this.state.myProperties.length > 0 ?
                                                <Button
                                                    className='mr-0 ml-2 buttonTextWeight mb-1'
                                                    color='primary'
                                                    disabled={isAtleastOneChecked && isAtleastOneChecked >= 0 ? false : true}
                                                    onClick={isAtleastOneChecked && isAtleastOneChecked >= 0 ? this.handleGLobalShare : null}
                                                >
                                                    {tl['share'] ? tl['share'] : 'Share'}
                                                </Button>
                                                : null
                                        }
                                        {
                                            this.state.myProperties && this.state.myProperties.length > 0 ?

                                                <Button
                                                    className='mr-0 ml-2 buttonTextWeight mb-1'
                                                    color='primary'
                                                    onClick={() => this.handleGlobalCheckBox(this.state.myProperties)}
                                                >
                                                    {this.state.isGlobalChecked || (isAtleastOneChecked !== 0) ?
                                                        tl['clearAll'] ? tl['clearAll'] : 'Clear All'
                                                        : tl['selectAll'] ? tl['selectAll'] : 'Select All'
                                                    }
                                                </Button>
                                                : null
                                        }
                                        {/* Filter Container show hide buttons based on screen width start here */}
                                        {(this.state.clientCriteria ||
                                            (!this.state.clientCriteria && this.state.myProperties && this.state.myProperties.length > 0)
                                        ) ?
                                            this.state.isFilterShown ?
                                                <Button
                                                    className='showInMobile mr-0 ml-2 buttonTextWeight mb-1'
                                                    color='primary'
                                                    onClick={() => this.setState({ isFilterShown: false })}
                                                >
                                                    {tl['hideFilters'] ? tl['hideFilters'] : 'Hide Filters'}
                                                </Button>

                                                :
                                                <Button
                                                    className='showInMobile mr-0 ml-2 buttonTextWeight mb-1'
                                                    color='primary'
                                                    onClick={() => this.setState({ isFilterShown: true })}
                                                >
                                                    {tl['showFilters'] ? tl['showFilters'] : 'Show Filters'}
                                                </Button>
                                            : null
                                        }
                                        {/* Filter Container show hide buttons based on screen width end here */}
                                    </div>
                                    : <div className='text-right'>
                                        {
                                            this.state.myProperties && this.state.myProperties.length > 0 ?
                                                <Button
                                                    className='mr-0 ml-2 buttonTextWeight mb-1'
                                                    color='primary'
                                                    disabled={isAtleastOneChecked && isAtleastOneChecked >= 0 ? false : true}
                                                    onClick={isAtleastOneChecked && isAtleastOneChecked >= 0 ? this.handleGLobalShare : null}
                                                >
                                                    {tl['share'] ? tl['share'] : 'Share'}
                                                </Button>
                                                : null
                                        }
                                        {
                                            this.state.myProperties && this.state.myProperties.length > 0 ?
                                                <Button
                                                    color='primary'
                                                    type='button'
                                                    disabled={isAtleastOneChecked && isAtleastOneChecked >= 0 ? false : true}
                                                    onClick={isAtleastOneChecked && isAtleastOneChecked >= 0 ? () => this.deleteConfirmAction() : null}
                                                    className='mr-0 ml-2 buttonTextWeight mb-1'
                                                >
                                                    {tl['delete'] ? tl['delete'] : 'Delete'}
                                                </Button>
                                                : null
                                        }
                                        {
                                            this.state.myProperties && this.state.myProperties.length > 0 ?

                                                <Button
                                                    className='mr-0 ml-2 buttonTextWeight mb-1'
                                                    color='primary'
                                                    onClick={() => this.handleGlobalCheckBox(this.state.myProperties)}
                                                >
                                                    {this.state.isGlobalChecked || (isAtleastOneChecked !== 0) ?
                                                        tl['clearAll'] ? tl['clearAll'] : 'Clear All'
                                                        : tl['selectAll'] ? tl['selectAll'] : 'Select All'
                                                    }
                                                </Button>
                                                : null
                                        }
                                        <Link
                                            to={{
                                                pathname: `/add_property`,
                                                state: {
                                                    fromScreen: window.location.pathname,
                                                    fromCriteria: this.state.clientCriteria,
                                                    fromData: this.state.myProperties,
                                                    ClientIdForMatcheProps: this.state.ClientIdForMatcheProps,
                                                    currentFilterCriteria: this.state.currentFilterCriteria,
                                                    tl: tl,
                                                }
                                            }}
                                        >
                                            <Button className='mr-0 ml-2 buttonTextWeight mb-1'
                                                color='primary'
                                            >
                                                {tl['addProperty'] ? tl['addProperty'] : 'Add Property'}
                                            </Button>
                                        </Link>
                                        {/* Filter Container show hide buttons based on screen width start here */}
                                        {(this.state.clientCriteria ||
                                            (!this.state.clientCriteria && this.state.myProperties && this.state.myProperties.length > 0)
                                        ) ?
                                            this.state.isFilterShown ?
                                                <Button
                                                    className='showInMobile mr-0 ml-2 buttonTextWeight mb-1'
                                                    color='primary'
                                                    onClick={() => this.setState({ isFilterShown: false })}
                                                >
                                                    {tl['hideFilters'] ? tl['hideFilters'] : 'Hide Filters'}
                                                </Button>

                                                :
                                                <Button
                                                    className='showInMobile mr-0 ml-2 buttonTextWeight mb-1'
                                                    color='primary'
                                                    onClick={() => this.setState({ isFilterShown: true })}
                                                >
                                                    {tl['showFilters'] ? tl['showFilters'] : 'Show Filters'}
                                                </Button>
                                            : null
                                        }
                                        {/* Filter Container show hide buttons based on screen width end here */}
                                    </div>
                        }
                    </div>}
                    {/* Page header buttons end here */}
                </Row>
                <Row className='justify-content-center mt-3 mb-0 mx-2 mx-md-2'>
                    {/* Apply filters container starts here */}
                    {(
                        (this.state.isFilterShown || window.innerWidth > 575) &&
                        (this.state.clientCriteria || (window.location.pathname === '/search_properties') ||
                            (!this.state.clientCriteria && this.state.myProperties && this.state.myProperties.length > 0)
                        )
                    ) && this.hidElement ?
                        <Col sm={5} md={4} lg={3} className='filtersContainer shadowCss mb-2 mt-1 px-2'>
                            <Row>
                                <ScrollBar>
                                    <div className="row mx-0">
                                        <div className="col">
                                            <form className="form myPropertiesSearchScroll" onSubmit={handleSubmit(this.applyFilter)}>
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['propertyFor'] ? tl['propertyFor'] : 'Property For'}
                                                    </span>
                                                    <div className="form__form-group-field row mx-0">
                                                        <Field
                                                            className='col-12 col-md-6 px-0'
                                                            name={'propertyFor'}
                                                            component={RadioButton}
                                                            label={tl && tl['sale'] ? tl['sale'] : 'Sale'}
                                                            radioValue={'Sale'}
                                                            defaultChecked={false}
                                                        />
                                                        <Field
                                                            className='col-12 col-md-6 px-0'
                                                            name={'propertyFor'}
                                                            component={RadioButton}
                                                            label={tl['rent'] ? tl['rent'] : 'Rent'}
                                                            radioValue={'Rent'}
                                                            defaultChecked={false}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['propertyType'] ? tl['propertyType'] : 'Property Type'}
                                                    </span>
                                                    <div className="form__form-group-field row mx-0">
                                                        <Field
                                                            className='col-12 col-lg-6 px-0'
                                                            name={'propertyType'}
                                                            component={RadioButton}
                                                            label={tl['1rk'] ? tl['1rk'] : '1RK'}
                                                            radioValue={'1RK'}
                                                            defaultChecked={false}
                                                            onChange={(e) => this.handleChange('propertyType', e)}
                                                        />
                                                        <Field
                                                            className='col-12 col-lg-6 px-0'
                                                            name={'propertyType'}
                                                            component={RadioButton}
                                                            label={tl['flat'] ? tl['flat'] : 'Flat'}
                                                            radioValue={'Flat'}
                                                            defaultChecked={false}
                                                            onChange={(e) => this.handleChange('propertyType', e)}
                                                        />
                                                        <Field
                                                            className='col-12 col-lg-6 px-0'
                                                            name={'propertyType'}
                                                            component={RadioButton}
                                                            label={tl['duplex'] ? tl['duplex'] : 'Duplex'}
                                                            radioValue={'Duplex'}
                                                            defaultChecked={false}
                                                            onChange={(e) => this.handleChange('propertyType', e)}
                                                        />
                                                        <Field
                                                            className='col-12 col-lg-6 px-0'
                                                            name={'propertyType'}
                                                            component={RadioButton}
                                                            label={tl['bungalow'] ? tl['bungalow'] : 'Bungalow'}
                                                            radioValue={'Bungalow'}
                                                            defaultChecked={false}
                                                            onChange={(e) => this.handleChange('propertyType', e)}
                                                        />
                                                        <Field
                                                            className='col-12 col-lg-6 px-0'
                                                            name={'propertyType'}
                                                            component={RadioButton}
                                                            label={tl['commercial'] ? tl['commercial'] : 'Commercial'}
                                                            radioValue={'Commercial'}
                                                            defaultChecked={false}
                                                            onChange={(e) => this.handleChange('propertyType', e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['city'] ? tl['city'] : 'City'}
                                                    </span>
                                                    <div className="form__form-group-field">
                                                        <div className='col px-0'>
                                                            <Field
                                                                onRef={(ref) => (this.citySelectionRef = ref)}
                                                                name={'city'}
                                                                id='city'
                                                                type='city'
                                                                className='cityFilter'
                                                                component={Select}
                                                                options={cityOptions}
                                                                placeholder={tl['selectCity'] ? tl['selectCity'] : 'City...'}
                                                                maxHeight={120}
                                                                onChange={(e) => this.handleLocChange('city', e)}
                                                                isClearable={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['location'] ? tl['location'] : 'Location'}
                                                    </span>
                                                    <div className="form__form-group-field">
                                                        <div className='col px-0'>
                                                            <Field
                                                                onRef={(ref) => (this.localitySelectionRef = ref)}
                                                                name={'location'}
                                                                id='locality'
                                                                type='locality'
                                                                className='locFilter'
                                                                component={Select}
                                                                options={localityOptions}
                                                                placeholder={tl['locality'] ? tl['locality'] : 'Locality'}
                                                                maxHeight={120}
                                                                onChange={(e) => this.handleLocChange('locality', e)}
                                                                openMenuOnClick={false}
                                                                isClearable={true}
                                                                openMenuOnFocus={false}
                                                                city={this.state.city}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form__form-group mb-0">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {config.currencySymbol + ' '}
                                                        {tl['budget'] ? tl['budget'] : 'Budget'}
                                                    </span>
                                                    <div className="form__form-group-field row">
                                                        <div className="col-12 col-lg-6 px-0 pr-lg-1 mb-2">
                                                            <Field
                                                                name='totalPriceMin'
                                                                component={DefaultInput}
                                                                placeholder={tl['min'] ? tl['min'] : 'Min'}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-6 px-0 pl-lg-1 mb-2">
                                                            <Field
                                                                name='totalPriceMax'
                                                                component={DefaultInput}
                                                                placeholder={tl['max'] ? tl['max'] : 'Max'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form__form-group mb-0">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['area'] ? tl['area'] : 'Area'}{' (' + config.areaUnits + ')'}
                                                    </span>
                                                    <div className="form__form-group-field row">
                                                        <div className="col-12 col-lg-6 px-0 pr-lg-1 mb-2">
                                                            <Field
                                                                name='builtUpAreaMin'
                                                                component={DefaultInput}
                                                                placeholder={tl['min'] ? tl['min'] : 'Min'}
                                                            />
                                                        </div>
                                                        <div className="col-12 col-lg-6 px-0 pl-lg-1 mb-2">
                                                            <Field
                                                                name='builtUpAreaMax'
                                                                component={DefaultInput}
                                                                placeholder={tl['max'] ? tl['max'] : 'Max'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['facing'] ? tl['facing'] : 'facing'}
                                                    </span>
                                                    <div className="form__form-group-field mb-1">
                                                        <div className="col px-0">
                                                            <Field
                                                                onRef={(ref) => (this.searchFacingSelectRef = ref)}
                                                                name={'facing'}
                                                                id={'facing'}
                                                                component={Select}
                                                                options={[
                                                                    { label: tl['north'] ? tl['north'] : 'North', value: 'North' },
                                                                    { label: tl['east'] ? tl['east'] : 'East', value: 'East' },
                                                                    { label: tl['west'] ? tl['west'] : 'West', value: 'West' },
                                                                    { label: tl['south'] ? tl['south'] : 'South', value: 'South' },
                                                                    { label: tl['northEast'] ? tl['northEast'] : 'North East', value: 'North East' },
                                                                    { label: tl['northWest'] ? tl['northWest'] : 'North West', value: 'North West' },
                                                                    { label: tl['southEast'] ? tl['southEast'] : 'South East', value: 'South East' },
                                                                    { label: tl['southWest'] ? tl['southWest'] : 'South West', value: 'South West' },
                                                                ]}
                                                                defaultValue={null}
                                                                placeholder={tl['select'] ? tl['select'] : 'Select'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Hide Bedrooms If selected property type is 1RK or Commercial */}
                                                {this.state.propertyType && (this.state.propertyType === '1RK' || this.state.propertyType === 'Commercial') ?
                                                    null :
                                                    <div className="form__form-group">
                                                        <span className="form__form-group-label textUpperCase">
                                                            {tl['bedRooms'] ? tl['bedRooms'] : 'Bedrooms'}
                                                        </span>
                                                        <div className="form__form-group-field row mx-0">
                                                            <Field
                                                                className='col-12 col-md-6 px-0'
                                                                name={'bedRooms'}
                                                                component={RadioButton}
                                                                label={'1'}
                                                                radioValue={'1'}
                                                                defaultChecked={false}
                                                            />
                                                            <Field
                                                                className='col-12 col-md-6 px-0'
                                                                name={'bedRooms'}
                                                                component={RadioButton}
                                                                label={'2'}
                                                                radioValue={'2'}
                                                                defaultChecked={false}
                                                            />
                                                            <Field
                                                                className='col-12 col-md-6 px-0'
                                                                name={'bedRooms'}
                                                                component={RadioButton}
                                                                label={'3'}
                                                                radioValue={'3'}
                                                                defaultChecked={false}
                                                            />
                                                            <Field
                                                                className='col-12 col-md-6 px-0'
                                                                name={'bedRooms'}
                                                                component={RadioButton}
                                                                label={'4'}
                                                                radioValue={'4'}
                                                                defaultChecked={false}
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['availability'] ? tl['availability'] : 'Availability'}
                                                    </span>
                                                    <div className="form__form-group-field row mx-0">
                                                        <Field
                                                            className='col-12 col-md-12 px-0'
                                                            name={'availability'}
                                                            component={RadioButton}
                                                            label={tl['underConstruction'] ? tl['underConstruction'] : 'Under Construction'}
                                                            radioValue={'Under Construction'}
                                                            defaultChecked={false}
                                                        />
                                                        <Field
                                                            className='col-12 col-md-12 px-0'
                                                            name={'availability'}
                                                            component={RadioButton}
                                                            label={tl['readyToMove'] ? tl['readyToMove'] : 'Ready to Move'}
                                                            radioValue={'Ready to Move'}
                                                            defaultChecked={false}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form__form-group">
                                                    <span className="form__form-group-label textUpperCase">
                                                        {tl['furnishingType'] ? tl['furnishingType'] : 'Furnishing Type'}
                                                    </span>
                                                    <div className="form__form-group-field row mx-0">
                                                        <Field
                                                            className='col-12 col-md-12 px-0'
                                                            name={'furnishingType'}
                                                            component={RadioButton}
                                                            label={tl['unFurnished'] ? tl['unFurnished'] : 'Un-Furnished'}
                                                            radioValue={'Un-Furnished'}
                                                            defaultChecked={false}
                                                        />
                                                        <Field
                                                            className='col-12 col-md-12 px-0'
                                                            name={'furnishingType'}
                                                            component={RadioButton}
                                                            label={tl['semiFurnished'] ? tl['semiFurnished'] : 'Semi-Furnished'}
                                                            radioValue={'Semi-Furnished'}
                                                            defaultChecked={false}
                                                        />
                                                        <Field
                                                            className='col-12 col-md-12 px-0'
                                                            name={'furnishingType'}
                                                            component={RadioButton}
                                                            label={tl['furnished'] ? tl['furnished'] : 'Furnished'}
                                                            radioValue={'Furnished'}
                                                            defaultChecked={false}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form__form-group mb-0">
                                                    <div className="form__form-group-field">
                                                        <div className="col px-0">
                                                            <Button
                                                                type='submit'
                                                                className="col buttonTextWeight mb-3"
                                                                color="primary"
                                                            >
                                                                {tl['applyFilters'] ? tl['applyFilters'] : 'Apply Filters'}
                                                            </Button>
                                                            <Button
                                                                type='button'
                                                                className="col buttonTextWeight mb-0"
                                                                color="secondary"
                                                                onClick={() => this.resetFilters(reset)}
                                                            >
                                                                {tl['reset'] ? tl['reset'] : 'Reset'}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </ScrollBar>
                            </Row>
                        </Col>
                        : null
                    }{/* Apply filters container starts here */}

                    {/* Property cards container starts here */}
                    <Col sm={7} md={8} lg={9} className='propertiesContainer px-2'>
                        <Row className='ml-sm-0'>
                            <ScrollBar className='myPropertiesListScroll'>
                                <div className={`row mx-0 ${!this.state.myProperties.length > 0 ? 'justify-content-center' : ''}`}>
                                    {this.state.myProperties && this.state.myProperties.length > 0 ?
                                        this.state.myProperties.map((item, index) => {
                                            return (
                                                <div
                                                    className="col-md-6 col-lg-4 pb-2 px-2 mt-3"
                                                    key={index}
                                                >
                                                    <Card className='propertyCardCss'>
                                                        <CardHeader className='p-0'>
                                                            {this.hidElement && <CheckBox
                                                                className='row propertyCheckBox'
                                                                key={item._id}
                                                                name={item._id}
                                                                id={item._id}
                                                                checked={item.isChecked}
                                                                onChange={() => this.handleCheckBox(item)}
                                                            />}
                                                            {item.propertyFor === 'Rent' ?
                                                                <label className='propertyPriceOverlay rent'>
                                                                    <div>{`${config.currencySymbol} ${item.totalPrice1 ? item.totalPrice1 :item.rent ? item.rent :  ''}`}</div>
                                                                    <div>{item.deposite ?
                                                                        `${config.currencySymbol} ${item.deposit1} ${tl['deposit'] ? tl['deposit'] : 'Deposit'}`
                                                                        : null}
                                                                    </div>
                                                                </label>
                                                                :
                                                                <label className='propertyPriceOverlay sale'>
                                                                    {`${config.currencySymbol} ${item.totalPrice1 ? item.totalPrice1 : item.totalPrice}`}
                                                                </label>
                                                            }
                                                            <div className="cornerRibbon">
                                                                {item.propertyFor}
                                                            </div>
                                                            {item.browsePhotos && item.browsePhotos.length > 0 && item.browsePhotos[0] && item.browsePhotos[0] ?
                                                                <div
                                                                    className="img__wrap cursorPointer"
                                                                    onClick={() => this.viewPhotosModal(item.browsePhotos)}
                                                                >
                                                                    <img
                                                                        className="propertyCardImage"
                                                                        alt=''
                                                                        src={`${config.imgUrl}myproperties/${item.browsePhotos[0]}`}
                                                                    />
                                                                    <p className="img__description">
                                                                        <AspectRatio />
                                                                    </p>
                                                                </div>
                                                                :
                                                                <img
                                                                    alt=''
                                                                    src={configImages.noPropImage}
                                                                    className='propertyCardImage'
                                                                />
                                                            }
                                                        </CardHeader>
                                                        <CardBody className='propertyCardBodyCss cursorPointer pt-2'>
                                                            <Link
                                                                onClick={e => this.handlePropCardClick(e)}
                                                                ref={(r) => this.propDetailsLink = r}
                                                                className='linkOverCardCss'
                                                                to={{
                                                                    pathname: `/propertyDetails/${item._id}`,
                                                                    state: {
                                                                        fromScreen: window.location.pathname,
                                                                        fromCriteria: this.state.clientCriteria,
                                                                        fromData: this.state.myProperties,
                                                                        ClientIdForMatcheProps: this.state.ClientIdForMatcheProps,
                                                                        currentFilterCriteria: this.state.currentFilterCriteria,
                                                                        criteriaForMatchedProps: this.state.matchedCriteria,
                                                                        tl: tl,
                                                                        state: this.state.matchedCriteria,
                                                                        matchedFromScreen: this.state.matchedFromScreen,
                                                                        propDetails: item
                                                                    }
                                                                }}
                                                            >
                                                                <Col sm={12} className='px-0'>
                                                                    <div className="row mx-0">
                                                                        <div className="col-10 px-0  align-self-start">
                                                                            <span className='propertyCardHeading'>
                                                                                {item.title ? item.title : ''}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-2 px-0 align-self-start ml-auto">
                                                                            {window.location.pathname && window.location.pathname === '/my_properties' ?
                                                                                item.customerId && item.customerId._id &&
                                                                                    loginUser && (item.customerId._id === loginUser._id) ?
                                                                                    <div className="d-flex justify-content-end">
                                                                                        <Link
                                                                                            to={{
                                                                                                pathname: `/properties/${item._id}`,
                                                                                                state: {
                                                                                                    fromScreen: window.location.pathname,
                                                                                                    fromCriteria: this.state.clientCriteria,
                                                                                                    fromData: this.state.myProperties,
                                                                                                    ClientIdForMatcheProps: this.state.ClientIdForMatcheProps,
                                                                                                    currentFilterCriteria: this.state.currentFilterCriteria,
                                                                                                    editData: item,
                                                                                                },
                                                                                            }}>
                                                                                            <FontAwesomeIcon
                                                                                                className='myPropEditIcon'
                                                                                                color='white'
                                                                                                icon='edit'
                                                                                                data-toggle="tool-tip"
                                                                                                title="Edit"
                                                                                                style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                                                                            />
                                                                                        </Link>
                                                                                        <FontAwesomeIcon className='myPropDeleteIcon'
                                                                                            color='white'
                                                                                            icon='trash-alt'
                                                                                            data-toggle="tool-tip"
                                                                                            title="Delete"
                                                                                            style={{ color: '#bf1725', width: '13', marginRight: '0px' }}
                                                                                            onClick={(event) => this.deleteConfirmAction(event, item._id)}
                                                                                        />
                                                                                    </div>
                                                                                    : null
                                                                                : window.location.pathname && window.location.pathname === '/search_properties' ?
                                                                                    <div className="d-flex justify-content-end">
                                                                                        {this.state[`${item._id}`] ?
                                                                                            <FavIconTrue
                                                                                                className='propertyCardUnFavIcon'
                                                                                                onClick={() => this.handlePropFav('unfav', item)}
                                                                                            />
                                                                                            : <FavIcon
                                                                                                className='propertyCardFavIcon'
                                                                                                onClick={() => this.handlePropFav('fav', item)}
                                                                                            />
                                                                                        }
                                                                                    </div>
                                                                                    : window.location.pathname && window.location.pathname === '/favourite_properties' ?
                                                                                        <div className="d-flex justify-content-end">
                                                                                            <FavIconTrue
                                                                                                className='propertyCardUnFavIcon'
                                                                                                onClick={() => this.handlePropFav('unfav', item)}
                                                                                            />
                                                                                        </div>
                                                                                        : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex propertyCardDetails'>
                                                                        <span className='py-1'>
                                                                            {item.propertyType === 'Commercial' || item.propertyType === '1RK' ?
                                                                                tl['plotArea'] ? tl['plotArea'] : 'Plot Area'
                                                                                : tl['builtUpArea'] ? tl['builtUpArea'] : 'Built-Up Area'
                                                                            }
                                                                            {' : '}
                                                                            {item.builtUpArea ?
                                                                                `${(parseInt(item.builtUpArea)).toLocaleString('en-IN')} ${item.areaUnits}`
                                                                                : null
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className="row mx-0 align-items-center propertyCardDetails">
                                                                        <span className='secondaryText'>
                                                                            {item.availability}
                                                                        </span>
                                                                       { this.hidElement && <ShareIcon
                                                                            className='shareIconAlignment cursorPointer ml-auto'
                                                                            onClick={(event) => this.handleSingleShare(event, item)}
                                                                        />}
                                                                    </div>
                                                                    <div className="row mx-0 align-items-center propertyCardDetails">
                                                                        <span>
                                                                            {item.furnishingType}
                                                                        </span>
                                                                        <EyeIcon size={16} className='ml-auto' />
                                                                        <span className='pl-1'>
                                                                            {item.agentSummaryViewCount}
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                            </Link>
                                                        </CardBody>
                                                        <CardFooter className='py-0 propertyCardFooter propertyCardDetails'>
                                                            <Link
                                                                onClick={e => this.handlePropCardClick(e)}
                                                                ref={(r) => this.propDetailsLink = r}
                                                                className='linkOverCardCss'
                                                                to={{
                                                                    pathname: `/propertyDetails/${item._id}`,
                                                                    state: {
                                                                        fromScreen: window.location.pathname,
                                                                        fromCriteria: this.state.clientCriteria,
                                                                        fromData: this.state.myProperties,
                                                                        ClientIdForMatcheProps: this.state.ClientIdForMatcheProps,
                                                                        currentFilterCriteria: this.state.currentFilterCriteria,
                                                                        criteriaForMatchedProps: this.state.matchedCriteria,
                                                                        tl: tl,
                                                                        state: this.state.matchedCriteria,
                                                                        matchedFromScreen: this.state.matchedFromScreen,
                                                                    }
                                                                }}
                                                            >
                                                                {item.showContactInfo ?
                                                                    <div onClick={() => this.handleContactInfo(item)} className='secondaryText'>
                                                                        {item.agentInfo && item.agentInfo.displayName ?
                                                                            <div className='px-0 col-12 text-right'>
                                                                                <span className='textCapitalize'>
                                                                                    {item.agentInfo.displayName}
                                                                                    <UserIcon className='contactInfoIcon' />
                                                                                </span>
                                                                            </div>
                                                                            : null
                                                                        }
                                                                        {item.agentInfo && item.agentInfo.phoneNumber ?
                                                                            <div className='px-0 col-12 text-right'>
                                                                                <span>
                                                                                    {item.agentInfo.phoneNumber}
                                                                                    <CallIcon className='contactInfoIcon' />
                                                                                </span>
                                                                            </div>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                    : null
                                                                }
                                                                <div className='d-flex'>
                                                                    <div className='py-1'>
                                                                        <span className='secondaryText'>
                                                                            <CalenderQuetionIcon className='howLongIcon' />
                                                                            {dateFormats.isHowLong(item.created)}
                                                                        </span>
                                                                    </div>
                                                                    {this.hidElement && <div className='ml-auto my-auto' color='primary'>
                                                                        {!(window.location.pathname === '/my_properties') ?
                                                                            <span
                                                                                className={item.showContactInfo ? 'contactInfoToggle2' : 'contactInfoToggle1'}
                                                                                onClick={() => this.handleContactSeller(item)}
                                                                            >
                                                                                {tl['contactSeller'] ? tl['contactSeller'] : 'Contact Seller'}
                                                                            </span>
                                                                            : null
                                                                        }
                                                                    </div>}
                                                                    { false && 
                                                                        <div className='ml-auto my-auto' color='primary'>
                                                                            <span
                                                                                className={item.showContactInfo ? 'contactInfoToggle2' : 'contactInfoToggle1'}
                                                                                onClick={() => this.handleContactInfo(item)}
                                                                            >
                                                                                {
                                                                                    item.showContactInfo ?
                                                                                        tl['hideAgentInfo'] ? tl['hideAgentInfo'] : 'Hide Agent Info'
                                                                                        : tl['showAgentInfo'] ? tl['showAgentInfo'] : 'Show Agent Info'
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className='ml-auto my-auto' color='primary'>
                                                                </div>
                                                            </Link>
                                                        </CardFooter>
                                                    </Card>
                                                </div>
                                            )
                                        })
                                        : window.location.pathname && window.location.pathname === '/search_properties' ?
                                            <div className='row mx-0 d-flex justify-content-center emptyPropsContainer'>
                                                <div className='col my-auto'>
                                                    <div>
                                                        <div className='text-center'>
                                                            <h4>
                                                                {this.state.clientCriteria ?
                                                                    tl['noResultsFound'] ? tl['noResultsFound'] : 'No results found'
                                                                    : tl['pleaseSelectYourReq'] ? tl['pleaseSelectYourReq'] : 'Please select your requirements'
                                                                }
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : window.location.pathname && window.location.pathname === '/matched_properties' ?
                                                <div className='row mx-0 d-flex justify-content-center emptyPropsContainer'>
                                                    <div className='col my-auto'>
                                                        <div>
                                                            <div className='text-center'>
                                                                <h4>
                                                                    {tl['noMatchedProps'] ? tl['noMatchedProps'] : 'No matched properties'}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div className='row mx-0 d-flex justify-content-center emptyPropsContainer'>
                                                    <div className='col my-auto'>
                                                        <div>
                                                            <div className='text-center'>
                                                                <h4>
                                                                    {this.state.clientCriteria ?
                                                                        tl['noResultsFound'] ? tl['noResultsFound'] : 'No results found'
                                                                        : tl['noProps'] ? tl['noProps'] : 'No properties'
                                                                    }
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                    }
                                </div>
                            </ScrollBar>
                        </Row>
                        <Row className='align-items-end listScreenPaginator'>
                            <Col>
                                {this.state.myProperties && this.state.myProperties.length > 0 && this.state.pagination && this.state.pagination.totalCount ?
                                    <Paginator
                                        className='paginatorStyles'
                                        totalRecords={this.state.pagination.totalCount}
                                        first={this.state.first}
                                        page={this.state.page}
                                        rows={this.state.rows}
                                        onPageChange={(e) => this.onPageChange(e, this.state.currentFilterCriteria ? this.state.currentFilterCriteria : this.state.filterCriteria)}
                                        style={{ padding: 10, backgroundColor: 'none', border: 'none' }}
                                    >
                                    </Paginator>
                                    : null
                                }
                            </Col>
                        </Row>
                    </Col>
                    {/* Property cards container ends here */}
                </Row>

                {/* Redirect to Previous screen from Matched Properties */}
                {this.state.redirectToPrevScreenFromMatchedProps ?
                    <Redirect
                        to={{
                            pathname: this.state.matchedFromScreen,
                            state: { tl: tl }
                        }}
                    />
                    : null
                }

                {/* Redirect to Home screen after logout*/}
                {this.state.isRedirectToHome ?
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { tl: tl }
                        }}
                    />
                    : null
                }

                {/* Photos Viewer */}
                {this.state.isViewPhotosModalOpen ?
                    <ViewPhotosModal
                        isViewPhotosModalOpen={this.state.isViewPhotosModalOpen}
                        propertyPhotos={this.state.propertyPhotos}
                        closeViewPhotosModal={this.viewPhotosModal}
                        tl={tl}
                    />
                    : null
                }

                {/* Share Limit Crossed Alert */}
                {this.state.isOpenShareLimitModal ?
                    <ShareLimitModal
                        isOpenShareLimitModal={this.state.isOpenShareLimitModal}
                        closeShareLimitModal={() => { this.setState({ isOpenShareLimitModal: false }) }}
                        tl={tl}
                    />
                    : null
                }

                {/* Client Select Modal  */}
                {this.state.isOpenClientSelectModel ?
                    <ClientSelectModel
                        isOpenClientSelectModel={this.state.isOpenClientSelectModel}
                        closeClientSelectModal={this.closeClientSelectModal}
                        clientCriteria={this.state.clientCriteria}
                        properties={this.state.saveSearchProperties}
                        saveSearchFilterObject={this.state.saveSearchFilterObject}
                        tl={tl}
                    />
                    : null
                }

                {/* Share Property/ies Modal */}
                {this.state.isOpenSharePropsModal ?
                    <SharePropsModal
                        isOpenSharePropsModal={this.state.isOpenSharePropsModal}
                        selectedProperties={this.state.selectedProperties}
                        closeSharePropsModal={this.closeSharePropsModal}
                        tl={tl}
                    />
                    : null
                }

                {/* Delete Selected Property modal */}
                {this.state.openConfirmActionModal ?
                    <ConfirmActionModal
                        openConfirmActionModal={this.state.openConfirmActionModal}
                        closeConfirmActionModal={this.closeConfirmActionModal}
                        deleteSelectedRow={this.deleteSelectedRow}
                        favSelectedClients={this.favSelectedClients}
                        actionType={this.state.actionType}
                        actionTitle={this.state.actionTitle}
                        redirectToLogin={this.redirectToHome}
                        tl={tl}
                    />
                    : null
                }

                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                        tl={tl}
                    />
                    : null
                }

                {/* Contact seller Modal */}
                {this.state.isOpenContactSellerModal ?
                    <ContactSellerModal
                        isOpenContactSellerModal={this.state.isOpenContactSellerModal}
                        PropertyDetails={this.state.contactProperty}
                        tl={tl}
                        closeContactSellerModal={this.closeContactSellerModal}
                    />
                    : null
                }

                {/* Login Form Modal */}
                {this.state.isOpenLoginFormModal ?
                    <LoginFormModal
                        isOpenLoginFormModal={this.state.isOpenLoginFormModal}
                        closeLoginFormModal={this.closeLoginFormModal}
                        tl={tl}
                    />
                    : null
                }
            </div >
        );
    }
}

CommonPropertiesScreen = reduxForm({
    form: 'searchProps', // a unique identifier for this form
    enableReinitialize: true,
    validate,
    asyncValidate,
    asyncBlurFields: ['totalPriceMax', 'totalPriceMin', 'builtUpAreaMin', 'builtUpAreaMax']
})(CommonPropertiesScreen);

CommonPropertiesScreen = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(CommonPropertiesScreen);

const commProps = connect(mapDispatchToProps)(CommonPropertiesScreen);

export default commProps
