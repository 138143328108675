import React, { PureComponent } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarProfile from './TopbarProfile';
import Notifications from './NotificationsList';
import fetchMethodRequest from '../../../config/service';

import LoginFormModal from '../../LogIn/components/LoginFormModal';
import { getLoginData } from '../../../redux/actions/loginAction';

import { connect } from 'react-redux';

const noProfileImage = require('../../../assets/img/profile/userProfile.png');

// Triggers when store state is updated
const mapStateToProps = state => {
  return state;
};

// a connected React component will be able to send messages to the store based on actions
function mapDispatchToProps(dispatch) {
  return {
    changeLoginData: loginUser => {
      dispatch(getLoginData(loginUser))
    }
  };
}

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      loginUserRole: '',
      tl: JSON.parse(localStorage.getItem('langJson')) ? JSON.parse(localStorage.getItem('langJson')) : {},
      lang: (localStorage.getItem('lang') && localStorage.getItem('lang') !== 'undefined') ? localStorage.getItem('lang') : null
    }

  }

  componentDidMount = async () => {
    // await this.changeLanguage();
  }

  // Get lang JSON
  changeLanguage = async (lan) => {
    let lang;
    if (lan) {
      lang = lan;
    } else if (this.state.lang) {
      lang = this.state.lang
    } else {
      lang = 'en'
    }

    let url = `auth/languages?language=${lang}`;
    fetchMethodRequest('GET', url).then(async resp => {
      if (resp) {
        await localStorage.setItem('lang', lang);
        await localStorage.setItem('langJson', JSON.stringify(resp));
        await this.setState({ tl: resp, lang: lang })
        return;
      } else {
        return;
      }
    }).catch(err => {
      return err;
    })

  }

  // Close Login Form Modal
  closeLoginFormModal = async (user) => {
    if (user && user.email) {
      await this.setState({
        loginUser: user,
        isOpenLoginFormModal: false,
        isLoading: false,
      })
      await this.props.changeLoginData(user);
    } else {
      await this.setState({
        isOpenLoginFormModal: false,
        isLoading: false,
      })
    }
  }
  getPageHeading = (tl) => {
    let currentPath = window.location.pathname;
    const headings = {
      '/settings': tl && tl['settings'] || 'Settings',
      '/dashboard': tl && tl['dashboard'] || 'Dashboard',
      '/clients': tl && tl['clients'] || 'Clients',
      '/savedSearchesList': tl && tl['savedSearches'] || 'Saved Searches',
      '/matchedPropertiesList': tl && tl['matchedProps'] || 'Matched Properties',
      '/my_properties': tl && tl['myProperties'] || 'My Properties',
      '/matched_properties': tl && tl['matchedProps'] || 'Matched Properties',
      '/search_properties': tl && tl['searchProperties'] || 'Matched Properties'
    };
    return headings[currentPath] || currentPath.includes('/search_properties/') =='/search_properties/:sharedCriteriaId' ? 'Matched Properties' : 'Matched Properties' 
  };

  // Open Login Form Modal
  openLoginFormModal = async () => {
    await this.setState({
      isOpenLoginFormModal: true,
      isLoading: false,
    })
  }

  render() {
    const { tl } = this.state;
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            {/* <NavLink
              style={{ color: 'white' ,whiteSpace: 'nowrap' }}
              className="topbar__logo ml-3"
              to="/"
            >
              
            </NavLink> */}
             <NavLink className="topbar__logo" to="dashboard" />
          </div>
          <div className="mobileView_topbar">
            <span>{this.getPageHeading(tl)}</span>
          </div>
          {/* {localStorage.getItem('loginCredentials') && localStorage.getItem('loginCredentials') !== 'undefined' ?
            <div className="topbar__right">
              <NavLink activeClassName='activeTopBar' className="hideInMobile topbar_navlink textUpperCase py-auto" to='/my_properties'>{tl['myProperties'] ? tl['myProperties'] : 'MY PROPERTIES'}</NavLink>
              <NavLink activeClassName='activeTopBar' className="hideInMobile topbar_navlink textUpperCase py-auto" to="/search_properties">{tl['search'] ? tl['search'] : 'SEARCH'}</NavLink>
              <NavLink activeClassName='activeTopBar' className="hideInMobile topbar_navlink textUpperCase py-auto" to="/favourite_properties">{tl['favourites'] ? tl['favourites'] : 'FAVOURITES'}</NavLink>

              <Notifications tl={tl} />
              <TopbarProfile changeLanguage={this.changeLanguage} tl={tl} />
            </div>
            :
            <div className="topbar__right">
              <div
                className="topbar_navlink textUpperCase py-auto align-items-center"
                onClick={this.openLoginFormModal}
              >
                <img
                  className="hideInMobile topbar__avatar-img mr-2"
                  src={noProfileImage}
                  alt=''
                />
                {tl['login'] ? tl['login'] : 'Login'}
              </div>
            </div>
          } */}
        </div>

        {/* Login Form Modal */}
        {this.state.isOpenLoginFormModal ?
          <LoginFormModal
            isOpenLoginFormModal={this.state.isOpenLoginFormModal}
            closeLoginFormModal={this.closeLoginFormModal}
            tl={tl}
          />
          : null
        }
      </div>
    );
  }
}

// Connect Component to Actions
const TopbarConnect = connect(mapStateToProps, mapDispatchToProps)(Topbar);

export default withRouter(TopbarConnect);
