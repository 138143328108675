import React from "react";
import { Modal } from "reactstrap";

//config
import config from '../../../config/config';
import configImages from "../../../config/configImages";

class ViewPhotosModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayPhoto: '',
            index: 0
        }
    }

    componentDidMount = () => {
        let photos = this.props.propertyPhotos;
        let index = this.state.index
        if (photos && photos.length && photos.length > 0) {
            let displayPhoto = `${config.imgUrl}myproperties/${photos[index]}`
            this.setState({ displayPhoto })
        }
    }

    //changing images when click on left and right arrows
    changeImageWhenClickOnIcons = (ind) => {
        let photos = this.props.propertyPhotos;
        let index = ind || ind === 0 ? ind : this.state.index
        if (photos && photos.length && photos.length > 0) {
            if (ind === -1) {
                index = photos.length - 1
            } else if (ind === photos.length) {
                index = 0
            }
            if (index <= photos.length - 1 && index >= 0) {
                let displayPhoto = `${config.imgUrl}property/${photos[index].name}`
                this.setState({ displayPhoto, index })
            }
        }
    }

    render() {
        const { displayPhoto, index } = this.state;
        const { propertyPhotos } = this.props;


        return (
            <Modal
                isOpen={this.props.isViewPhotosModalOpen}
                toggle={() => this.props.closeViewPhotosModal('close')}
                className='photoViewModal modal-dialog-centered'
                style={{ maxWidth: '100%', background: config.modalBackground, height: '100%', margin: '0px' }}
            >
                <div className='photosModalCloseIcon'>
                    <img
                        src={configImages.closeIcon}
                        alt='closeIcon'
                        className='closeIcon'
                        onClick={() => this.props.closeViewPhotosModal('close')}
                    />
                </div>
                <div className="content-between">
                    <div className='sliderNext'>
                        <div className='slider-left' onClick={() => this.changeImageWhenClickOnIcons(index - 1)} />
                    </div>
                    <div className='col'>
                        <img
                            src={displayPhoto}
                            className='image-width'
                            alt=''
                        />
                        <div className='imagesCount'>{index + 1} / {propertyPhotos.length}</div>
                        <div className='container horizontal_scroll'>
                            <div className='text-center'>
                                {this.props.propertyPhotos.map((item, ind) => {
                                    if (item && item.name) {
                                        return (
                                            <img
                                                key={ind}
                                                style={index === ind ? { opacity: 1 } : { opacity: 0.3 }}
                                                src={`${config.imgUrl}property/${item.name}`}
                                                className='previewImg'
                                                alt=''
                                                onClick={() => this.changeImageWhenClickOnIcons(ind)}
                                            />
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='sliderNext'>
                        <div className='slider-right' onClick={() => this.changeImageWhenClickOnIcons(index + 1)} />
                    </div>
                </div>
            </Modal>
        );
    }
}
export default ViewPhotosModal